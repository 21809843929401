import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import PageHeading from '../../../core/components/PageHeading';

import LEGACY_ClientOffer from '../../components/LEGACY_ClientOffer';

import { JobPostProvider } from '../../context/JobPostContext';

import { jobPostsRoutes } from '../../routes/constants';

import { useAuth } from '../../../core/hooks/useAuth';
import { useJobPost } from '../../hooks/useJobPost';

import { useFetchSelectedCareProQuery } from '../../api/queries/useFetchSelectedCareProQuery';
import { useOfferCarePro } from '../../api/mutations/useOfferCarePro';

import { StyledJobPostOfferPage } from './styles';

const JobPostOfferPageContent = () => {
	const history = useHistory();
	const { careProId } = useParams();

	const { authUser } = useAuth();
	const { jobPost, isLoadingJobPost } = useJobPost();

	const { data: carePro, isLoading: isLoadingCarePro } = useFetchSelectedCareProQuery(careProId);
	const offerCarePro = useOfferCarePro();

	if (isLoadingJobPost || isLoadingCarePro) {
		return <>Loading Job Post</>;
	}

	const goToJobPostPage = (jobPostId) => {
		history.push(jobPostsRoutes.jobPost(jobPostId));
	};

	const offerCareProHandler = async (hourlyRate, workTimeFrames) => {
		console.log(hourlyRate, workTimeFrames);
		try {
			await offerCarePro.mutateAsync({
				jobPostId: jobPost.id,
				careProId: carePro.id,
				hourlyRate,
				workTimeFrames,
			});

			history.push(jobPostsRoutes.jobPost(jobPost.id));
		} catch (error) {
			console.error('Offer care pro failed', error);
		}
	};

	return (
		<StyledJobPostOfferPage>
			<PageHeading>
				Make offer to: {carePro.displayName} for job "{jobPost.title}"
			</PageHeading>

			<LEGACY_ClientOffer
				patient={authUser}
				jobPost={jobPost}
				carePlan={authUser.carePlan}
				selectedProvider={carePro}
				offerCareProHandler={offerCareProHandler}
				goToJobPostPage={goToJobPostPage}
			/>
		</StyledJobPostOfferPage>
	);
};

const JobPostOfferPage = () => {
	return (
		<JobPostProvider>
			<JobPostOfferPageContent />
		</JobPostProvider>
	);
};

export default JobPostOfferPage;

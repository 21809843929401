import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import styled from 'styled-components';

import { searchProviders, fetchProivder } from 'reducers/admin';

import SetTable from 'AppComp/SetTable';

import ProviderSearchController from './ProviderSearchController';

import { ColumnFlex, RowFlex } from 'basicStyles';

import { PROVIDER_NAV_WIDTH } from 'constants';

import { device } from 'AppComp/styled/StyledMediaQuery';

export const ParentContainer = styled.div`
	flex: 1;

	border: 12px;

	@media ${device.laptop} {
		padding-right: calc(${PROVIDER_NAV_WIDTH} / 2);
	}
`;

export const PHDColumn = styled(ColumnFlex)`
	padding: 30px 4px;
	border-radius: 12px;

	@media ${device.mobileS} {
		padding: 30px 16px;
	}

	@media ${device.laptop} {
		padding: 30px 32px 32px 52px;
	}
`;

class ProviderSearchHandler extends ProviderSearchController {
	render() {
		return (
			<ParentContainer
				style={{
					minHeight: '100vh',
				}}
			>
				<PHDColumn>
					<ColumnFlex fullWidth>
						<SetTable
							title='Care Pro'
							renderRows={this.renderRows}
							renderLegend={this.renderLegend}
							leftPadding='16px'
						>
							{
								//this.renderSearchBar()
							}
						</SetTable>
					</ColumnFlex>
				</PHDColumn>
			</ParentContainer>
		);
	}
}

const mapStateToProps = ({ admin }) => {
	return {
		providers: admin.providers,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		searchProviders: (input) => dispatch(searchProviders(input)),
		fetchProivder: (id) => dispatch(fetchProivder(id)),
		showInfoModel: (params) => dispattch(showInfoModel(params)),
		goToProvider: (id) => dispatch(push('/admin/carepro/' + id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProviderSearchHandler);

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Colors from 'colors';
import useWindowSize from 'scripts/useWindowSize';

import 'react-calendar/dist/Calendar.css';
import { toast } from 'react-toastify';

import { APPLICATION_STATUS_ENUM, TOAST_SUCCESS_CONFIG, TOAST_ERROR_CONFIG } from 'constants';

import { BackDropBg, ColumnFlex, MODAL_BACKDROP, MODAL_PANEL, ModalPanel } from 'basicStyles';

import Title from 'AppComp/Title';
import Text from 'AppComp/Text';
import Button from 'AppComp/Button';

import { PHWhiteCon } from 'AppComp/provider/ProviderHomeDashboard';

const InputConTemp = styled.div``;
import { DateSection, StartTimeSection, EndTimeSection } from './AddSessionSections';
import { add } from 'lodash';
const EditScheduleSession = (props) => {
	const { carePlan, handleModalExitClick, event, handleSuccess, updateSchedule, removeSchedule } =
		props;

	if (event === null) {
		return null;
	}
	// props

	// states
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [selectedStartTime, setSelectedStartTime] = useState('');
	const [selectedEndTime, setSelectedEndTime] = useState('');
	// util hooks
	const { width, height } = useWindowSize();

	useEffect(() => {
		setInitDateFromEvent();
	}, []);
	const setInitDateFromEvent = () => {
		const date = new Date(event.start);
		setSelectedDate(date);
		setSelectedStartTime(date.toLocaleTimeString());
		setSelectedEndTime(new Date(event.end).toLocaleTimeString());
	};

	const handleEditCareSession = async () => {
		try {
			// ensure selected time & selected end time are not undefined or null
			if (!selectedStartTime || !selectedEndTime) {
				toast.error('Please select a start and end time', TOAST_ERROR_CONFIG);
				return;
			}

			if (selectedStartTime > selectedEndTime) {
				toast.error('End time must be after start time', TOAST_ERROR_CONFIG);

				return;
			}

			console.log('selectedStartTime', selectedStartTime);

			// get the selectedStarTtime and add it to the selected date so we can get a complete date object
			const selectedStartTimeDate = new Date(selectedStartTime);
			console.log('selectedStartTimeDate', selectedStartTimeDate);

			const start = createDate(selectedDate, selectedStartTime);
			const end = createDate(selectedDate, selectedEndTime);

			updateSchedule(start, end, event.resource.workTimeFrame.id);

			toast.success('Care session Updated', TOAST_SUCCESS_CONFIG);
			await handleSuccess(true);
		} catch (err) {
			console.error(err);
		}
	};

	const handleDeleteSession = async () => {
		try {
			// ensure selected time & selected end time are not undefined or null
			if (!selectedStartTime || !selectedEndTime) {
				toast.error('Please select a start and end time', TOAST_ERROR_CONFIG);
				return;
			}

			removeSchedule(event.resource.workTimeFrame.id);

			await handleSuccess(true);
		} catch (err) {
			console.error(err);
		}
	};

	console.log('selectedDate', selectedDate);

	const createDate = (date, time) => {
		// Convert selectedDate to UTC
		const utcSelectedDate = new Date(
			date.getUTCFullYear(),
			date.getUTCMonth(),
			date.getUTCDate()
		);

		// Parse start and end times in UTC
		const utcTime = new Date(`${utcSelectedDate.toISOString().slice(0, 10)}T${time}`);

		const timeUTC = new Date(utcSelectedDate);
		timeUTC.setHours(utcTime.getHours(), utcTime.getMinutes(), utcTime.getSeconds());
		return timeUTC;
	};
	return (
		<>
			<BackDropBg
				onClick={() => handleModalExitClick()}
				{...MODAL_BACKDROP}
				aria-hidden='true'
			></BackDropBg>
			<ModalPanel aria-label='Sidebar' {...MODAL_PANEL}>
				<ColumnFlex gap='12px'>
					<PHWhiteCon
						style={{
							minWidth: width < 800 ? '100%' : '440px',
							minHeight: '600px',
							backgroundColor: 'white',
							borderRadius: '24px',
						}}
					>
						<ColumnFlex gap='32px' padding='24px 0' fullWidth center>
							<Title
								nubtitle
								color={Colors.greenLightTeal}
								margin='0 0 20px 0'
								fontWeight='700'
							>
								Edit Care Session
							</Title>
							<ColumnFlex
								style={{
									width: '320px',
								}}
								gap='24px'
							>
								<DateSection
									selectedDate={selectedDate}
									setSelectedDate={setSelectedDate}
								/>
								<StartTimeSection
									selectedStartTime={selectedStartTime}
									setSelectedStartTime={setSelectedStartTime}
								/>
								<EndTimeSection
									selectedEndTime={selectedEndTime}
									setSelectedEndTime={setSelectedEndTime}
								/>
								{/* <UserInputSection />
								<UserInputSection /> */}
							</ColumnFlex>
							<ColumnFlex center gap='12px'>
								<Button
									onClick={() => handleEditCareSession()}
									backgroundColor={Colors.primary.primary}
									width={'300px'}
									height={'70px'}
									borderRadius='32px'
									style={{
										shadow: 'none',
									}}
								>
									<Text fontWeight='500' color={'white'} largeText>
										Save
									</Text>
								</Button>
								<Button
									onClick={() => handleDeleteSession()}
									backgroundColor={Colors.red}
									width={'300px'}
									height={'70px'}
									borderRadius='32px'
									style={{
										shadow: 'none',
									}}
								>
									<Text fontWeight='500' color={'white'} largeText>
										Delete
									</Text>
								</Button>
								<Text
									onClick={() => handleModalExitClick()}
									style={{
										cursor: 'pointer',
									}}
									fontWeight='100'
									color={'black'}
								>
									exit
								</Text>
							</ColumnFlex>
						</ColumnFlex>
					</PHWhiteCon>
				</ColumnFlex>
			</ModalPanel>
		</>
	);
};

export default EditScheduleSession;

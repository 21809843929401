import React from 'react';
import { useFormik } from 'formik-latest';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { FormContainer, Input, ErrorMessage } from '../../../../../corev2/Forms';
import { PrimaryButton } from '../../../../../corev2/Buttons';

import FormWrapper from '../FormWrapper';
import InputContainer from '../InputContainer';

import personalInfoFormValidationSchema from './validation';

const PersonalInfoForm = (props) => {
	const { initialValues, personalInfoFormHandler } = props;

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: (values) => personalInfoFormHandler(values),
		validationSchema: toFormikValidationSchema(personalInfoFormValidationSchema),
		enableReinitialize: true,
	});

	return (
		<FormContainer>
			<FormWrapper onSubmit={formik.handleSubmit}>
				<InputContainer>
					<Input
						name='firstName'
						type='text'
						onChange={formik.handleChange}
						placeholder='First Name'
						value={formik.values.firstName}
					/>

					{formik.errors.firstName && (
						<ErrorMessage> {formik.errors.firstName} </ErrorMessage>
					)}
				</InputContainer>

				<InputContainer>
					<Input
						name='lastName'
						type='text'
						onChange={formik.handleChange}
						placeholder='Last Name'
						value={formik.values.lastName}
					/>

					{formik.errors.lastName && (
						<ErrorMessage> {formik.errors.lastName} </ErrorMessage>
					)}
				</InputContainer>

				<InputContainer>
					<Input
						name='displayName'
						type='text'
						onChange={formik.handleChange}
						placeholder='Display Name'
						value={formik.values.displayName}
					/>

					{formik.errors.displayName && (
						<ErrorMessage> {formik.errors.displayName} </ErrorMessage>
					)}
				</InputContainer>

				<PrimaryButton type='submit' size='small'>
					Next
				</PrimaryButton>
			</FormWrapper>
		</FormContainer>
	);
};

export default PersonalInfoForm;

import React, { useEffect, useState } from 'react';

import PageHeading from '../../../../core/components/PageHeading';
import InfoForm from '../../../components/InfoForm';

import { useUpdateSetTraitsMutation } from '../../../api/mutations/useUpdateSetTraitsMutation';
import { useCheckUserSessionForCarePlanQuery } from '../../../api/queries/useCheckUserSessionForCarePlanQuery';

import { useAuth } from '../../../../core/hooks/useAuth';

import { StyledInfoPage } from './styles';

const Infopage = () => {
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [displayName, setDisplayName] = useState('');
	const [dob, setDob] = useState('');
	const [gender, setGender] = useState('');

	const [serverError, setServerError] = useState('');

	const updateSetTraitsMutation = useUpdateSetTraitsMutation();

	const { authUser } = useAuth();

	const { data } = useCheckUserSessionForCarePlanQuery();

	useEffect(() => {
		if (data) {
			const traits = data.carePlan.carePlanSet.setData;

			setFirstName(traits[0]?.value?.trait_value.replace(/\"/g, '') || '');
			setLastName(traits[1]?.value?.trait_value.replace(/\"/g, '') || '');
			setDisplayName(traits[2]?.value?.trait_value.replace(/\"/g, '') || '');

			const day = traits[3]?.value?.trait_value.replace(/\"/g, '') || '';
			const month = traits[4]?.value?.trait_value.replace(/\"/g, '') || '';
			const year = traits[5]?.value?.trait_value.replace(/\"/g, '') || '';

			if (day && month && year) {
				const dobValue = new Date(`${year}-${month}-${day}`).toISOString().split('T')[0];
				setDob(dobValue);
			} else {
				setDob('');
			}

			setGender(traits[6]?.value?.trait_value.replace(/\"/g, '') || '');
		}
	}, [data]);

	const infoFormHandler = async (values) => {
		try {
			await updateSetTraitsMutation.mutateAsync({
				setId: authUser.carePlan.carePlanSet.id,
				traits: [
					{ trait_id: 60, value: values.firstName },
					{ trait_id: 61, value: values.lastName },
					{ trait_id: 62, value: values.displayName },
					{ trait_id: 63, value: new Date(values.dob).getDate().toString() },
					{
						trait_id: 64,
						value: new Date(values.dob).toLocaleDateString('default', {
							month: '2-digit',
						}),
					},
					{ trait_id: 65, value: new Date(values.dob).getFullYear().toString() },
					{ trait_id: 66, value: values.gender },
				],
			});
		} catch (error) {
			console.error('Care plan client info form failed', error);
			setServerError(error[0].message);
		}
	};

	return (
		<StyledInfoPage>
			<PageHeading>Client Information</PageHeading>
			<InfoForm
				initialValues={{
					firstName: firstName,
					lastName: lastName,
					displayName: displayName,
					dob: dob,
					gender: gender,
				}}
				infoFormHandler={infoFormHandler}
				serverError={serverError}
			/>
		</StyledInfoPage>
	);
};

export default Infopage;

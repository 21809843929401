import React from 'react';
import { useFormik } from 'formik-latest';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { FormContainer, Input, ErrorMessage } from '../../../../../corev2/Forms';
import { PrimaryButton } from '../../../../../corev2/Buttons';

import FormWrapper from '../FormWrapper';
import InputContainer from '../InputContainer';

import accountInfoFormValidationSchema from './validation';

const AccountInfoForm = (props) => {
	const { initialValues, accountInfoHandler, serverError } = props;

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: (values) => accountInfoHandler(values),
		enableReinitialize: true,
		validationSchema: toFormikValidationSchema(accountInfoFormValidationSchema),
	});

	return (
		<FormContainer>
			<FormWrapper onSubmit={formik.handleSubmit}>
				<InputContainer>
					<Input
						name='email'
						type='email'
						onChange={formik.handleChange}
						placeholder='Email'
						value={formik.values.email}
					/>

					{formik.errors.email && <ErrorMessage> {formik.errors.email} </ErrorMessage>}
				</InputContainer>

				<InputContainer>
					<Input
						name='phone'
						type='tel'
						onChange={formik.handleChange}
						placeholder='Phone'
						value={formik.values.phone}
					/>

					{formik.errors.phone && <ErrorMessage> {formik.errors.phone} </ErrorMessage>}
				</InputContainer>

				<InputContainer>
					<Input
						name='password'
						type='password'
						onChange={formik.handleChange}
						placeholder='Password'
						value={formik.values.password}
					/>

					{formik.errors.password && (
						<ErrorMessage> {formik.errors.password} </ErrorMessage>
					)}
				</InputContainer>

				<InputContainer>
					<Input
						name='confirmPassword'
						type='password'
						onChange={formik.handleChange}
						placeholder='Confirm Password'
						value={formik.values.confirmPassword}
					/>

					{formik.errors.confirmPassword && (
						<ErrorMessage> {formik.errors.confirmPassword} </ErrorMessage>
					)}
				</InputContainer>

				<InputContainer>
					<PrimaryButton type='submit' size='small'>
						Next
					</PrimaryButton>

					{serverError && <ErrorMessage> {serverError} </ErrorMessage>}
				</InputContainer>
			</FormWrapper>
		</FormContainer>
	);
};

export default AccountInfoForm;

import React, { Component } from 'react';
import styled from 'styled-components';

import Colors from 'colors';

import SetTable from 'AppComp/SetTable';
import Text from 'AppComp/Text';
import Button from 'AppComp/Button';
import SearchDropDownHandler from 'AppComp/SearchDropDownHandler';
import Img from 'AppComp/Img';

const Container = styled.div`
	background-color: ${Colors.fullWhite};
	border-radius: 4px;
	width: 600px;
	height: 85%;
	margin-left: 32px;
`;

const CustomImage = styled(Img)`
	height: 70px;
	width: 70px;
	image-orientation: ;
`;

export default class ProviderSearchController extends Component {
	state = {
		options: [],
	};

	componentDidMount() {
		const { searchProviders, showInfoModel } = this.props;
		searchProviders();
	}
	handleChange = (selectedOption) => {
		this.setState({ selectedOption: selectedOption.value });
	};

	handleSearchSubmit = (input) => {
		const { searchProviders } = this.props;

		searchProviders(input);
	};

	loadOptions = (inputValue) => null;

	renderSearchBar = () => {
		const { selectedOption, options } = this.state;

		return (
			<Container>
				<SearchDropDownHandler
					options={options}
					handleChange={this.loadOptions}
					handleSearchSubmit={this.handleSearchSubmit}
					placeholder='Search Providers'
					showSuggestions
				/>
			</Container>
		);
	};

	renderRows = () => {
		const { providers, fetchProivder, goToProvider } = this.props;

		const countAcceptedActiveJobs = (jobs) => {
			if (jobs && Array.isArray(jobs)) {
				return jobs.reduce((total, job) => {
					if (job.offer_status === 'accepted' && job.job_status === 'active') {
						return total + 1;
					} else {
						return total;
					}
				}, 0);
			} else {
				return 0;
			}
		};

		if (providers.length === 0) {
			return (
				<SetTable.ColumnCon>
					<Text color='#969696' fontWeight='100' mediumText margin='16px'>
						Search for Providers
					</Text>
				</SetTable.ColumnCon>
			);
		} else {
			return providers.map((d, i) => {
				console.log('providers', d);

				return (
					<SetTable.RowAction>
						<SetTable.ColumnCon flex='1'>
							<CustomImage src={d.image_url} borderRadius='50%' />
						</SetTable.ColumnCon>
						<SetTable.ColumnCon flex='2'>
							<Text color='#2D2D2D' fontWeight='100' mediumText>
								{`${d.first_name} ${d.last_name}`}{' '}
								<span
									style={{
										fontWeight: 'normal',
										fontStyle: 'italic',
										fontSize: '10px',
										color: 'black',
									}}
								>
									{d.display_name ? `(${d.display_name})` : ''}
								</span>
							</Text>
						</SetTable.ColumnCon>
						<SetTable.ColumnCon flex='1'>
							<Text color='#2D2D2D' fontWeight='100' mediumText>
								{countAcceptedActiveJobs(d.jobs)}
							</Text>
						</SetTable.ColumnCon>
						<SetTable.ColumnCon flex='1'>
							<Text color='#2D2D2D' fontWeight='100' mediumText>
								Shifts amount
							</Text>
						</SetTable.ColumnCon>
						<SetTable.ColumnCon>
							<Text color='#2D2D2D' fontWeight='100' mediumText>
								{d.phone_number}
							</Text>
						</SetTable.ColumnCon>

						<SetTable.ColumnCon>
							<Button
								width='200px'
								color={Colors.blue}
								onClick={() => goToProvider(d.id)}
							>
								View Provider Info
							</Button>
						</SetTable.ColumnCon>
					</SetTable.RowAction>
				);
			});
		}
	};

	renderLegend = () => {
		return (
			<>
				<SetTable.ColumnCon>
					<Text fontStyle='italic' color='#969696' fontWeight='100' mediumText>
						Image
					</Text>
				</SetTable.ColumnCon>
				<SetTable.ColumnCon flex='2'>
					<Text fontStyle='italic' color='#969696' fontWeight='100' mediumText>
						Name
					</Text>
				</SetTable.ColumnCon>
				<SetTable.ColumnCon flex='1'>
					<Text fontStyle='italic' color='#969696' fontWeight='100' mediumText>
						Total Active Jobs
					</Text>
				</SetTable.ColumnCon>
				<SetTable.ColumnCon flex='1'>
					<Text fontStyle='italic' color='#969696' fontWeight='100' mediumText>
						Total Timsheets
					</Text>
				</SetTable.ColumnCon>
				<SetTable.ColumnCon>
					<Text fontStyle='italic' color='#969696' fontWeight='100' mediumText>
						Phone Number
					</Text>
				</SetTable.ColumnCon>

				<SetTable.ColumnCon />
			</>
		);
	};
}

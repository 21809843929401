import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';

import {
	SubTitle,
	Container,
	Title,
	GridContainer,
	Item,
	Label,
	Input,
} from '../ModelDataViews/CoreView';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import Colors from 'colors';
import { TOAST_SUCCESS_CONFIG, TOAST_ERROR_CONFIG } from 'constants';

import api from 'scripts/api';

import { SINGLE_DOCUMENT_MODEL } from 'reducers/graphqlModelTypes';

import Button from 'AppComp/Button';
import Text from 'AppComp/Text';
import Icon from 'AppComp/Icon';
import PageLoaderView from 'AppComp/PageLoaderView';
import FormButton from 'AppComp/FormButton';

import { RowFlex, ColumnFlex } from 'AppComp/styled/Basic';
import { device } from 'AppComp/styled/StyledMediaQuery';
import StyledTextInput, { TextArea } from 'AppComp/styled/StyledTextInput';

import { ParentContainer } from 'AppComp/admin/ProviderSearch/ProviderSearchHandler';
import { TextInfo, TextBlockInfo, ProviderInfo } from 'AppComp/admin/UserModel/UserModelBlocks';

import { PHWhiteCon } from 'AppComp/provider/ProviderHomeDashboard';
import { CustomAvatar } from 'AppComp/provider/ProfileInfo/ProfileInfoViews';
import { FormItemsLength } from 'AppComp/provider/POnboard/OnboardViews';

import {
	DisplayUploadedDocument,
	SingeDisplayUploadedDocument,
} from 'AppComp/patient/Documents/Upload';
import { ExpirationInput } from 'AppComp/patient/Documents/Upload';

import { PHDColumn } from '../ManageDocuments/ManageDocumentsHandler';
import DocumentDataView from '../ModelDataViews/DocumentDataView';

const CustomFormButton = styled(FormButton)`
	border-radius: 50px;
	${({ valid }) =>
		!valid &&
		`
		background-color: white;
		border: 1px solid ${Colors.greenLightTeal};
		p{
			color: ${Colors.greenLightTeal};
		}
	`}

	${({ valid }) =>
		valid &&
		`
		background-color: ${Colors.greenLightTeal};
		color: white;
		p{
			color: white;
		}
	`}
`;

const SwitchTabsButton = ({ selectedAction, setSelectedAction }) => {
	return (
		<RowFlex>
			<Button
				style={{
					borderRadius: '16px 0px 0 16px',
				}}
				margin='0 0 0 0 '
				backgroundColor={selectedAction === 1 ? Colors.primary.primary : 'white'}
				width={'200px'}
				height={'60px'}
				onClick={() => setSelectedAction(1)}
			>
				<Text
					style={{
						textAlign: 'center',
					}}
					bold
					color={selectedAction === 1 ? 'white' : Colors.primary.primary}
					midLarge
				>
					Approve
				</Text>
			</Button>

			<Button
				style={{
					borderRadius: '0 16px 16px 0px',
				}}
				margin='0 0 0 0 '
				backgroundColor={selectedAction === 2 ? Colors.red : 'white'}
				width={'200px'}
				height={'60px'}
				onClick={() => setSelectedAction(2)}
			>
				<Text
					style={{
						textAlign: 'center',
					}}
					bold
					color={selectedAction === 2 ? 'white' : Colors.red}
					midLarge
				>
					Reject
				</Text>
			</Button>
		</RowFlex>
	);
};

const ManageDocHandler = (props) => {
	console.log('ManageDocHandler props', props);

	const { history, location } = props;

	const [doc, setDoc] = useState(null);
	const [loading, setLoading] = useState(true);

	const [selectedAction, _setSelectedAction] = useState(0); // 0 = nothing, 1 = approve, 2 = reject
	const [showRejectReasonInput, setShowRejectReasonInput] = useState(false);

	const [expirationDate, setExpirationDate] = useState(null);

	useEffect(() => {
		const id = props.match.params.id;
		if (id) {
			fetchDocument();
		}
	}, []);

	const setSelectedAction = (action) => {
		if (action === 1) {
			handleApprove();
		} else if (action === 2) {
			setShowRejectReasonInput(true);
			//handleReject();
		}
	};

	useEffect(() => {
		if (doc) {
			const status = doc.status;

			let selected = 0;
			if (status === 'pending') {
				selected = 0;
			} else if (status === 'approved') {
				selected = 1;
			} else if (status === 'rejected') {
				selected = 2;
			}
			_setSelectedAction(selected);
		}
	}, [doc]);

	const handleApprove = async () => {
		const id = doc.id;
		try {
			const res = await api.graph({
				query: `mutation {
          approveDocument(document_id: ${id}) {
						${SINGLE_DOCUMENT_MODEL}
          }
        }`,
			});
			if (res.approveDocument) {
				setDoc(res.approveDocument);
				toast.success('Document Approved', TOAST_SUCCESS_CONFIG);
			}
		} catch (err) {
			if (Array.isArray(err)) {
				toast.error(err[0].message, TOAST_ERROR_CONFIG);
			} else {
				toast.error('Something went wrong', TOAST_ERROR_CONFIG);
			}
		}
	};

	const handleReject = async (rejectNotes) => {
		const id = doc.id;
		try {
			const res = await api.graph({
				query: `mutation {
          rejectDocument(document_id: ${id}, ${
					rejectNotes !== '' && `rejection_notes: "${rejectNotes}"`
				}) {
						${SINGLE_DOCUMENT_MODEL}
          }
        }`,
			});
			if (res.rejectDocument) {
				setDoc(res.rejectDocument);
				toast.success('Document Rejected', TOAST_SUCCESS_CONFIG);
			}
			setShowRejectReasonInput(false);
		} catch (err) {
			setShowRejectReasonInput(false);

			if (Array.isArray(err)) {
				toast.error(err[0].message, TOAST_ERROR_CONFIG);
			} else {
				toast.error('Something went wrong', TOAST_ERROR_CONFIG);
			}
		}
	};

	const handleSaveNotes = async (value) => {
		const id = doc.id;
		try {
			const res = await api.graph({
				query: `mutation {
          updateDocumentNote(document_id: ${id}, notes: "${value}") {
          	${SINGLE_DOCUMENT_MODEL}
          }
        }`,
			});
			if (res.updateDocumentNote) {
				setDoc(res.updateDocumentNote);
				toast.success('Notes saved', TOAST_SUCCESS_CONFIG);
			}
		} catch (err) {
			if (Array.isArray(err)) {
				toast.error(err[0].message, TOAST_ERROR_CONFIG);
			} else {
				toast.error('Something went wrong', TOAST_ERROR_CONFIG);
			}
		}
	};

	const handleRejectSubmit = (values, form) => {
		console.log('Personal notes - handleSubmit', values);

		handleReject(values.reject_notes);
	};

	const handleSubmit = (values, form) => {
		console.log('Personal notes - handleSubmit', values);
		handleSaveNotes(values.notes);
	};

	const fetchDocument = async () => {
		try {
			setLoading(true);

			const id = props.match.params.id;

			const docsRes = await api.graph({
				query: `{
					fetchAgencyDocument(document_id: ${id}) {
						${SINGLE_DOCUMENT_MODEL}
					}
				}`,
			});

			console.log('docsRes', docsRes);
			if (docsRes.fetchAgencyDocument) {
				setDoc(docsRes.fetchAgencyDocument);

				if (docsRes.fetchAgencyDocument.expiration_date) {
					setExpirationDate(docsRes.fetchAgencyDocument.expiration_date);
				}
				setLoading(false);

				//_setSelectedAction
			}
		} catch (err) {
			console.log('fetchDocument err', err);
		}
	};

	const updateDocumentExpirationDate = async (date) => {
		try {
			setLoading(true);

			const id = props.match.params.id;

			const _date = new Date(date);
			const sqlDate = _date.toISOString().slice(0, 19).replace('T', ' ');

			const docsRes = await api.graph({
				query: `mutation {
					updateDocumentExpirationDate(document_id: ${id}, expiration_date: "${sqlDate}") {
						${SINGLE_DOCUMENT_MODEL}
					}
				}`,
			});

			console.log('docsRes', docsRes);
			if (docsRes.updateDocumentExpirationDate) {
				setDoc(docsRes.updateDocumentExpirationDate);

				setLoading(false);
				setExpirationDate(date);
				toast.success('Document expiration updated', TOAST_SUCCESS_CONFIG);
			}
		} catch (err) {
			console.log('fetchDocument err', err);
		}
	};
	const renderUserInfo = () => {
		if (doc.uploaded_by === 'client') {
			const {
				first_name,
				last_name,
				phone_number,
				type,
				dob,
				gender,
				medical_notes,

				display_name,
			} = doc.carePlan;
			return (
				<ColumnFlex fullWidth justifyStart>
					<TextInfo
						title='Client Name'
						value={`${first_name} ${last_name}`}
						margin='0px 0 0 0'
					/>
					<TextInfo title='Display Name' value={display_name} margin='0px 0 0 0' />
					<RowFlex fullWidth wrap gap='8px'>
						<TextBlockInfo title='Client DOB' value={dob} margin='0px 0 0 0' />

						<TextBlockInfo title='Client Gender' value={gender} margin='8px 0 0 0' />
						<TextBlockInfo title='Type' value={type} margin='8px 0 0 0' />
					</RowFlex>
					<TextInfo title='Medical Notes' value={medical_notes} margin='8px 0 0 0' />
				</ColumnFlex>
			);
		} else if (doc.uploaded_by === 'carePro') {
			return (
				<ColumnFlex fullWidth justifyStart>
					<ProviderInfo {...doc.provider} />
				</ColumnFlex>
			);
		}
	};

	const renderStatus = () => {
		let status = Colors.yellow;
		if (doc.status === 'approved') {
			status = Colors.primary.primary;
		} else if (doc.status === 'rejected') {
			status = Colors.red;
		}
		return (
			<RowFlex gap='4px' alignCenter justifyStart>
				<div
					style={{
						backgroundColor: status,
						borderRadius: '50%',
						width: '20px',
						height: '20px',
					}}
				/>
				<Text color={status} bold>
					{doc.status}
				</Text>
			</RowFlex>
		);
		b;
	};

	console.log('doc', doc);
	return (
		<ParentContainer
			style={{
				minHeight: '100vh',
				position: 'relative',
				paddingTop: '40px',
			}}
		>
			<div
				style={{
					position: 'absolute',
					top: '30px',
					left: '0px',
					cursor: 'pointer',
				}}
			>
				<Icon size='xl' icon={faChevronLeft} onClick={() => history.goBack()} />
			</div>
			{loading && doc === null ? (
				<PageLoaderView />
			) : (
				<>
					<DocumentDataView {...doc} />
					<Title>Uploaded Files</Title>
					<GridContainer columns={4}>
						<SingeDisplayUploadedDocument uploaded={doc} />
					</GridContainer>
					<SubTitle>Document Notes</SubTitle>
					<Formik
						onSubmit={handleSubmit}
						initialValues={{
							notes: doc.notes,
						}}
						validate={(values) => {
							const errors = {};
							if (!values.notes) {
								errors.notes = 'Required';
							}
							return errors;
						}}
						autoComplete={'off'}
						render={(formProps) => {
							console.log(formProps);
							return (
								<Form
									style={{
										width: '100%',
									}}
									noValidate
								>
									<PHWhiteCon
										style={{
											backgroundColor: 'white',
											height: 'fit-content',
										}}
									>
										<ColumnFlex
											style={{
												borderRadius: '16px',
												backgroundColor: Colors.lightGrey,
												padding: '20px 10px 10px 10px',
											}}
											fullWidth
											margin='10px 0 12px 0'
											alignCenter
										>
											<ColumnFlex alignCenter fullWidth justifyCenter>
												<FormItemsLength
													margin='0px 0 0 0'
													width={'100%'}
													style={{
														maxWidth: 'none',
													}}
												>
													<Field
														component={TextArea}
														name='notes'
														label=''
														type='text'
														rows='10'
														maxWidth='none'
													/>
												</FormItemsLength>
												{formProps.values.notes !== doc.notes && (
													<CustomFormButton
														valid={formProps.isValid}
														width='130px'
														height='40px'
														type='submit'
													>
														Submit
													</CustomFormButton>
												)}
											</ColumnFlex>
										</ColumnFlex>
									</PHWhiteCon>
								</Form>
							);
						}}
					/>
					<SubTitle>Manage Document Approval</SubTitle>
					<GridContainer columns={3}>
						<div />
						<SwitchTabsButton
							selectedAction={selectedAction}
							setSelectedAction={setSelectedAction}
						/>
						<div />
					</GridContainer>
					{showRejectReasonInput && (
						<>
							<SubTitle>Rejection Reason</SubTitle>
							<Formik
								onSubmit={handleRejectSubmit}
								initialValues={{
									notes: '',
								}}
								autoComplete={'off'}
								render={(formProps) => {
									console.log(formProps);
									return (
										<Form
											style={{
												width: '100%',
											}}
											noValidate
										>
											<PHWhiteCon
												style={{
													backgroundColor: 'white',
													height: 'fit-content',
												}}
											>
												<ColumnFlex
													style={{
														borderRadius: '16px',
														backgroundColor: Colors.white,
														padding: '10px 10px 10px 10px',
													}}
													fullWidth
													margin='10px 0 12px 0'
													alignCenter
												>
													<ColumnFlex alignCenter fullWidth justifyCenter>
														<FormItemsLength
															margin='0px 0 0 0'
															width={'100%'}
															style={{
																maxWidth: 'none',
															}}
														>
															<Field
																component={TextArea}
																name='reject_notes'
																label=''
																type='text'
																rows='10'
																maxWidth='none'
															/>
														</FormItemsLength>
														<CustomFormButton
															valid={true}
															style={{
																borderRadius: '32px',
																backgroundColor: Colors.red,
															}}
															margin='0 0 0 0 '
															backgroundColor={Colors.red}
															width={'120px'}
															height={'30px'}
															type='submit'
														>
															<Text
																style={{
																	textAlign: 'center',
																}}
																bold
																color={Colors.red}
																midLarge
															>
																Reject
															</Text>
														</CustomFormButton>
													</ColumnFlex>
												</ColumnFlex>
											</PHWhiteCon>
										</Form>
									);
								}}
							/>
						</>
					)}
				</>
			)}
		</ParentContainer>
	);
};

export default ManageDocHandler;

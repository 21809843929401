import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { coreRoutes } from '../../routes/constants';
import { authRoutes } from '../../../auth/routes/constants';

import { useAuth } from '../../hooks/useAuth';

import { PrimaryButton, TransparentButton } from '../../../../../corev2/Buttons';
import MenuButton from './MenuButton';

import { colors } from '../../../../../corev2/utils';

import {
	StyledBanner,
	StyledHeader,
	StyledWrapper,
	StyledLogo,
	StyledNavButtonsWrapper,
	StyledNavText,
} from './styles';

const NavBar = (props) => {
	const [logoURL, setLogoURL] = useState(
		'https://find-your-independents.s3.amazonaws.com/images/FYIlogo.png'
	);
	const history = useHistory();

	const { statusUpdate, isAuthenticated, hasCompletedPlatformOnboarding } = useAuth();

	const goToHomePage = () => {
		history.push(coreRoutes.root);
	};

	const goToLoginPage = () => {
		history.push(authRoutes.login);
	};

	const goToOnboardingPage = () => {
		history.push(authRoutes.onboarding.home);
	};

	const goToCarePlanPage = () => {
		history.push(coreRoutes.carePlan);
	};

	const goToJobPostsPage = () => {
		history.push(coreRoutes.jobPosts);
	};

	const goToChatPage = () => {
		history.push(coreRoutes.chat);
	};

	const goToProfilePage = () => {
		history.push(coreRoutes.profile);
	};

	return (
		<>
			{statusUpdate && <StyledBanner>{statusUpdate}</StyledBanner>}

			<StyledHeader>
				<StyledWrapper>
					<StyledLogo onClick={goToHomePage} src={logoURL} />

					<StyledNavButtonsWrapper>
						{isAuthenticated && hasCompletedPlatformOnboarding && (
							<>
								<TransparentButton onClick={goToCarePlanPage}>
									<StyledNavText style={{ color: colors.primary.primary }}>
										Care Plan
									</StyledNavText>
								</TransparentButton>

								<TransparentButton onClick={goToChatPage}>
									<StyledNavText style={{ color: colors.primary.primary }}>
										Chat
									</StyledNavText>
								</TransparentButton>

								<TransparentButton onClick={goToJobPostsPage}>
									<StyledNavText style={{ color: colors.primary.primary }}>
										Care Jobs
									</StyledNavText>
								</TransparentButton>
							</>
						)}

						{!isAuthenticated && (
							<PrimaryButton size='small' onClick={goToLoginPage}>
								Login
							</PrimaryButton>
						)}

						{!isAuthenticated && (
							<TransparentButton size='small' onClick={goToOnboardingPage}>
								Signup
							</TransparentButton>
						)}

						{isAuthenticated && (
							<TransparentButton onClick={goToProfilePage}>
								<MenuButton />
							</TransparentButton>
						)}
					</StyledNavButtonsWrapper>
				</StyledWrapper>
			</StyledHeader>
		</>
	);
};

export default NavBar;

import { useQuery } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { CARE_NEEDS_MODEL } from '../../../core/api/models';

const checkUserSessionForCarePlan = async () => {
	try {
		const data = await api.graph({
			query: `{
				checkUserSession{
					id, 

					carePlan{
						id,
						
						carePlanSet {
							${CARE_NEEDS_MODEL}
						}

						careNeedsSet{
							${CARE_NEEDS_MODEL}
						}
					}
				}
			}`,
		});

		return data.checkUserSession;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useCheckUserSessionForCarePlanQuery = () => {
	return useQuery({
		queryKey: ['checkUserSessionForCarePlan'],
		queryFn: () => {
			return checkUserSessionForCarePlan();
		},
	});
};

import styled from 'styled-components';

import { colors } from '../../utils';

export const StyledFormContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;

	border-radius: 20px;
	margin-top: 20px;

	border: 1px solid ${colors.primary.primary};

	display: flex;
	flex-direction: column;
	justify-content: center;
`;

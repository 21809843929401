import styled from 'styled-components';

import { colors } from '../../../../../corev2/utils';

export const StyledJobStats = styled.div`
	width: 100%;
	padding: 20px;

	border: 1px solid ${colors.forms.formInputBorderColor};
	border-radius: 8px;

	background: ${colors.white};

	display: flex;
	justify-content: space-between;
	gap: 20px;
`;

import React, { useState } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import Colors from 'colors';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

import { ColumnFlex, RowFlex, MODAL_PANEL } from 'basicStyles';

import Text from 'AppComp/Text';

import TimeInput from 'AppComp/core/Forms/TimeInput';

const UpdatedStyledSelect = styled.select`
	padding: 0.6rem 0.2rem;
	padding-left: 18px;
	font-size: 22px;
	line-height: 1rem;
	font-weight: 100;
	display: block;
	width: 100%;
	border: none;
	border: 0.5px solid #ecf0f3;
	border-radius: 4px;
	color: black;
	border-radius: 12px;
	background-color: ${(props) => props.backgroundColor || Colors.lightGrey};
	&:focus {
		outline: none;
		background: ${(props) => props.backgroundColor || 'none'};
	}
	&:valid {
		outline: none;
		background: ${(props) => props.backgroundColor || 'none'};
	}
`;

const REPEAT_OPTIONS = [
	{
		label: 'One Time',
		value: 'one_time',
		id: 'one_time',
	},
	{
		label: 'Daily',
		value: 'daily',
		id: 'daily',
	},
	{
		label: 'Every Weekday',
		value: 'every_weekday',
		id: 'every_weekday',
	},
	// {
	// 	label: 'Weekly',
	// 	value: 'weekly',
	// 	id: 'weekly',
	// },
];
export const RepeatTimeSection = (props) => {
	const { selectedRepeatTimeFrame, setSelectedRepeatTimeFrame } = props;
	const renderOptions = () => {
		return REPEAT_OPTIONS.map((option) => {
			return (
				<option key={option.id} value={option.value}>
					{option.label}
				</option>
			);
		});
	};
	return (
		<ColumnFlex alignStart fullWidth gap='12px'>
			<Text largeText padding='0 8px' color={Colors.secondary.blue}>
				Repeat Every
			</Text>
			<ColumnFlex
				style={{
					position: 'relative',
				}}
				center
				fullWidth
			>
				<RowFlex
					style={{
						padding: '0 12px',
						backgroundColor: Colors.grey,
						borderRadius: '12px',
						height: '48px',
						width: '100%',
						cursor: 'pointer',
					}}
					center
				>
					<RowFlex
						fullWidth
						alignCenter
						padding='0 8px'
						style={{
							flex: 1,
						}}
					>
						<UpdatedStyledSelect
							backgroundColor={Colors.grey}
							value={selectedRepeatTimeFrame}
							onChange={(e) => setSelectedRepeatTimeFrame(e.target.value)}
							required
						>
							{renderOptions()}
						</UpdatedStyledSelect>
					</RowFlex>
				</RowFlex>

				<div
					style={{
						height: '3px',
						width: '94%',
						backgroundColor: Colors.secondary.blue,
					}}
				/>
			</ColumnFlex>
		</ColumnFlex>
	);
};
export const EndTimeSection = (props) => {
	const { setSelectedEndTime, selectedEndTime } = props;

	const handleTimeClick = (event) => {
		setSelectedEndTime(event.target.value);
	};

	return (
		<ColumnFlex alignStart fullWidth gap='12px'>
			<Text largeText padding='0 8px' color={Colors.secondary.blue}>
				End Time
			</Text>
			<ColumnFlex
				style={{
					position: 'relative',
				}}
				center
				fullWidth
			>
				<RowFlex
					style={{
						padding: '0 12px',
						backgroundColor: Colors.grey,
						borderRadius: '12px',
						height: '48px',
						width: '100%',
						cursor: 'pointer',
					}}
					center
				>
					<RowFlex
						fullWidth
						alignCenter
						padding='0 8px'
						style={{
							flex: 1,
						}}
					>
						<TimeInput onChange={handleTimeClick} value={selectedEndTime} required />
					</RowFlex>
				</RowFlex>

				<div
					style={{
						height: '3px',
						width: '94%',
						backgroundColor: Colors.secondary.blue,
					}}
				/>
			</ColumnFlex>
		</ColumnFlex>
	);
};
export const StartTimeSection = (props) => {
	const { selectedStartTime, setSelectedStartTime } = props;

	const handleTimeClick = (event) => {
		setSelectedStartTime(event.target.value);
	};

	return (
		<ColumnFlex alignStart fullWidth gap='12px'>
			<Text largeText padding='0 8px' color={Colors.secondary.blue}>
				Start Time
			</Text>
			<ColumnFlex
				style={{
					position: 'relative',
				}}
				center
				fullWidth
			>
				<RowFlex
					style={{
						padding: '0 12px',
						backgroundColor: Colors.grey,
						borderRadius: '12px',
						height: '48px',
						width: '100%',
						cursor: 'pointer',
					}}
					center
				>
					<RowFlex
						fullWidth
						alignCenter
						padding='0 8px'
						style={{
							flex: 1,
						}}
					>
						<TimeInput onChange={handleTimeClick} value={selectedStartTime} required />
					</RowFlex>
				</RowFlex>

				<div
					style={{
						height: '3px',
						width: '94%',
						backgroundColor: Colors.secondary.blue,
					}}
				/>
			</ColumnFlex>
		</ColumnFlex>
	);
};
export const DateSection = (props) => {
	const { selectedDate, setSelectedDate } = props;
	const [showCal, setShowCal] = useState(false);

	const handleDateClick = (date) => {
		// ensure date is not in the past
		// if (date < new Date()) {
		// 	toast.error('Cant create a care session in the past', TOAST_ERROR_CONFIG);

		// 	return;
		// }
		// hide the cal
		setShowCal(false);
		// set the date
		setSelectedDate(date);
	};
	return (
		<ColumnFlex alignStart fullWidth gap='12px'>
			<Text largeText padding='0 8px' color={Colors.secondary.blue}>
				What day
			</Text>
			<ColumnFlex
				style={{
					position: 'relative',
				}}
				center
				fullWidth
			>
				<RowFlex
					onClick={() => setShowCal(!showCal)}
					style={{
						padding: '0 12px',
						backgroundColor: Colors.grey,
						borderRadius: '12px',
						height: '48px',
						width: '100%',
						cursor: 'pointer',
					}}
					center
				>
					<RowFlex
						fullWidth
						alignCenter
						padding='0 8px'
						style={{
							flex: 1,
						}}
					>
						<Text largeText>{selectedDate.toDateString()}</Text>
					</RowFlex>
					<div
						style={{
							width: '16px',
						}}
					>
						<AnimatePresence mode='wait' initial={false}>
							{showCal && (
								<motion.div
									style={{
										cursor: 'pointer',
									}}
									{...MODAL_PANEL}
									onClick={() => setShowCal(false)}
								>
									<Text bold largeText>
										X
									</Text>
								</motion.div>
							)}
						</AnimatePresence>
					</div>
				</RowFlex>
				<AnimatePresence mode='wait' initial={false}>
					{showCal && (
						<motion.div
							style={{
								position: 'absolute',
								top: '100%',
								left: '0',
								width: '100%',
								zIndex: 100,
							}}
							{...MODAL_PANEL}
						>
							<Calendar onChange={handleDateClick} value={selectedDate} />
						</motion.div>
					)}
				</AnimatePresence>
				<div
					style={{
						height: '3px',
						width: '94%',
						backgroundColor: Colors.secondary.blue,
					}}
				/>
			</ColumnFlex>
		</ColumnFlex>
	);
};

import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

const createUserStripeIntent = async () => {
	try {
		const data = await api.graph({
			query: `mutation { createUserStripeIntent }`,
		});

		return data.createUserStripeIntent;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useCreateUserStripeIntentMutation = () => {
	return useMutation({
		mutationKey: ['createUserStripeIntent'],
		mutationFn: () => {
			return createUserStripeIntent();
		},
	});
};

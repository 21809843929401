import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ProfilePage from '../pages/ProfilePage';

import { profileRoutes } from './constants';

const ProfileRoutes = () => {
	return (
		<Switch>
			<Route path={profileRoutes.home}>
				<ProfilePage />
			</Route>
		</Switch>
	);
};

export default ProfileRoutes;

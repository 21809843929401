import React from 'react';

import LEGACY_ClientChat from '../../components/LEGACY_ClientChat';

import { useSendMessageMutation } from '../../api/mutations/useSendMessageMutation';
import { useFetchConversationsQuery } from '../../api/queries/useFetchConversationsQuery';

const ChatPage = () => {
	const { data, isLoading: isLoadingChat } = useFetchConversationsQuery();

	const sendMessage = useSendMessageMutation();

	const sendMessageHandler = async (conversationId, text, careProid) => {
		try {
			await sendMessage.mutateAsync({
				conversationId,
				text,
				careProid,
			});
		} catch (error) {
			console.error('Send message failed', error);
		}
	};

	if (isLoadingChat) {
		return <>Loading chats</>;
	}

	return (
		<LEGACY_ClientChat
			conversations={data.conversations}
			sendMessageHandler={sendMessageHandler}
		/>
	);
};

export default ChatPage;

import { z } from 'zod';

const accountInfoFormValidationSchema = z
	.object({
		email: z.string().email('Invalid email address'),
		phone: z
			.string()
			.regex(
				/^\(\d{3}\) \d{3}-\d{4}$/,
				'Invalid phone number. Expected format: (XXX) XXX-XXXX'
			),
		password: z.string().min(6, 'Password must be at least 6 characters long'),
		confirmPassword: z.string(),
	})
	.refine((data) => data.password === data.confirmPassword, {
		message: 'Passwords do not match',
		path: ['confirmPassword'],
	});

export default accountInfoFormValidationSchema;

import React from 'react';
import { useFormik } from 'formik-latest';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { FormContainer, Input, Select, ErrorMessage } from '../../../../../corev2/Forms';
import { PrimaryButton } from '../../../../../corev2/Buttons';

import FormWrapper from '../../components/FormWrapper';
import InputContainer from '../../components/InputContainer';

import infoFormValidationSchema from './validation';

const InfoForm = (props) => {
	const { serverError, initialValues, infoFormHandler } = props;

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: async (values) => infoFormHandler(values),
		validationSchema: toFormikValidationSchema(infoFormValidationSchema),
		enableReinitialize: true,
	});

	return (
		<FormContainer>
			<FormWrapper onSubmit={formik.handleSubmit}>
				<InputContainer>
					<Input
						name='firstName'
						type='text'
						onChange={formik.handleChange}
						placeholder='First Name'
						value={formik.values.firstName}
					/>

					{formik.errors.firstName && (
						<ErrorMessage> {formik.errors.firstName} </ErrorMessage>
					)}
				</InputContainer>

				<InputContainer>
					<Input
						name='lastName'
						type='text'
						onChange={formik.handleChange}
						placeholder='Last Name'
						value={formik.values.lastName}
					/>

					{formik.errors.lastName && (
						<ErrorMessage> {formik.errors.lastName} </ErrorMessage>
					)}
				</InputContainer>

				<InputContainer>
					<Input
						name='displayName'
						type='text'
						onChange={formik.handleChange}
						placeholder='Display Name'
						value={formik.values.displayName}
					/>

					{formik.errors.displayName && (
						<ErrorMessage> {formik.errors.displayName} </ErrorMessage>
					)}
				</InputContainer>

				<InputContainer>
					<Input
						name='dob'
						type='date'
						onChange={formik.handleChange}
						placeholder='Date of Birth'
						value={formik.values.dob}
					/>

					{formik.errors.dob && <ErrorMessage> {formik.errors.dob} </ErrorMessage>}
				</InputContainer>

				<InputContainer>
					<Select
						name='gender'
						onChange={formik.handleChange}
						value={formik.values.gender}
					>
						<option>Gender</option>
						<option value='male'>Male</option>
						<option value='female'>Female</option>
					</Select>

					{formik.errors.gender && <ErrorMessage> {formik.errors.gender} </ErrorMessage>}
				</InputContainer>

				<InputContainer>
					<PrimaryButton size='small' type='submit'>
						Save
					</PrimaryButton>

					{serverError && <ErrorMessage> {serverError} </ErrorMessage>}
				</InputContainer>
			</FormWrapper>
		</FormContainer>
	);
};

export default InfoForm;

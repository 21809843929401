import React, { useEffect, useState } from 'react';

import PageHeading from '../../../../core/components/PageHeading';
import LogisticsForm from '../../../components/LogisticsForm';

import { useUpdateSetTraitsMutation } from '../../../api/mutations/useUpdateSetTraitsMutation';
import { useCheckUserSessionForCarePlanQuery } from '../../../api/queries/useCheckUserSessionForCarePlanQuery';

import { useAuth } from '../../../../core/hooks/useAuth';

import { StyledLogisticsPage } from './styles';

const LogisticsPage = () => {
	const [address1, setAddress1] = useState('');
	const [address2, setAddress2] = useState('');
	const [city, setCity] = useState('');
	const [zip, setZip] = useState('');
	const [state, setState] = useState('');
	const [codeToEnterHome, setCodeToEnterHome] = useState('');
	const [whereToPark, setWhereToPark] = useState('');
	const [extraInfo, setExtraInfo] = useState('');

	const [serverError, setServerError] = useState('');

	const updateSetTraitsMutation = useUpdateSetTraitsMutation();
	const { authUser } = useAuth();

	const { data } = useCheckUserSessionForCarePlanQuery();

	useEffect(() => {
		if (data) {
			const traits = data.carePlan.carePlanSet.setData;
			setAddress1(traits[16]?.value?.trait_value.replace(/\"/g, '') || '');
			setAddress2(traits[17]?.value?.trait_value.replace(/\"/g, '') || '');
			setCity(traits[18]?.value?.trait_value.replace(/\"/g, '') || '');
			setZip(traits[19]?.value?.trait_value.replace(/\"/g, '') || '');
			setState(traits[20]?.value?.trait_value.replace(/\"/g, '') || '');
			setCodeToEnterHome(traits[21]?.value?.trait_value.replace(/\"/g, '') || '');
			setWhereToPark(traits[22]?.value?.trait_value.replace(/\"/g, '') || '');
			setExtraInfo(traits[23]?.value?.trait_value.replace(/\"/g, '') || '');
		}
	}, [data]);

	const logisticsFormHandler = async (values) => {
		try {
			await updateSetTraitsMutation.mutateAsync({
				setId: authUser.carePlan.carePlanSet.id,
				traits: [
					{ trait_id: 76, value: values.address1 },
					{ trait_id: 77, value: values.address2 },
					{ trait_id: 78, value: values.city },
					{ trait_id: 79, value: values.zip },
					{ trait_id: 80, value: values.state },
					{ trait_id: 81, value: values.codeToEnterHome },
					{ trait_id: 82, value: values.whereToPark },
					{ trait_id: 83, value: values.extraInfo },
				],
			});
		} catch (error) {
			console.error('Care plan logistics form failed', error);
			setServerError(error[0].message);
		}
	};

	return (
		<StyledLogisticsPage>
			<PageHeading>Logistics</PageHeading>

			<LogisticsForm
				initialValues={{
					address1: address1,
					address2: address2,
					city: city,
					zip: zip,
					state: state,
					codeToEnterHome: codeToEnterHome,
					whereToPark: whereToPark,
					extraInfo: extraInfo,
				}}
				logisticsFormHandler={logisticsFormHandler}
				serverError={serverError}
			/>
		</StyledLogisticsPage>
	);
};

export default LogisticsPage;

import React from 'react';
import {
	faFile,
	faGraduationCap,
	faComments,
	faAddressCard,
	faClipboardList,
	faTimes,
	faDollarSign,
	faUserTimes,
	faEnvelope,
	faUserCheck,
	faTrash,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import changeCase from 'change-case';

import { ColumnFlex, RowFlex } from 'basicStyles';
import Colors from 'colors';
import Text from 'AppComp/Text';
import Button from 'AppComp/Button';

import {
	ImgContainer,
	FittedImg,
	Section,
	renderCertifications,
	renderCoversations,
	ProviderInfo,
	PatientInfo,
	Conversation,
	CarePlanInfo,
	ScheduleSession,
	GenericSessionSection,
	PaymentInfo,
	ChangeFyiCommissionSection,
	BareBoneCalculator,
	ReportSectionViews,
	VerificationInfo,
	UserVerificationInfo,
	TextBlockInfo,
	TextInfo,
} from './UserModelBlocks';

export const BasicCalculator = (props) => {
	return <BareBoneCalculator />;
};

export const CarePlanSection = (props) => {
	const {
		dischargeProvider,
		updatedCommissionStatus,
		handleUpdateFyiCommission,

		admin,
		handleDeletingPatient,
		user,
	} = props;
	return (
		<Section title={`${props.first_name}'s Care Plan`} defaultActive>
			<CarePlanInfo {...props} />
			{admin && admin.id && handleDeletingPatient && (
				<Section title='Delete all data for this User' icon={faTrash}>
					<Button
						onClick={() => handleDeletingPatient(user.id)}
						margin='16px  auto'
						backgroundColor={Colors.red}
						width='250px'
					>
						Delete EVERYTHING
					</Button>
				</Section>
			)}
			{user && user.verification && (
				<Section title='Verification Info' icon={faUserCheck}>
					<UserVerificationInfo {...user.verification} />
				</Section>
			)}

			<BareBoneCalculator />
		</Section>
	);
};

export const PaymentSection = (props) => {
	const { provider, user } = props;

	return (
		<>
			<PaymentInfo {...props} />
			<Section title='CarePro' icon={faGraduationCap}>
				<ColumnFlex alignStart fullWidth>
					<ImgContainer>
						<FittedImg src={provider.image_url} />
					</ImgContainer>
					<ColumnFlex fullWidth padding='0 0 0 12px'>
						<ProviderInfo {...provider} />
					</ColumnFlex>
				</ColumnFlex>
			</Section>
			<Section title='Patient' icon={faAddressCard}>
				<PatientInfo {...user} />
			</Section>

			<BareBoneCalculator />
		</>
	);
};

export const PatientSection = (props) => {
	const {
		carePlan,
		conversation,
		dischargeProvider,
		updatedCommissionStatus,
		handleUpdateFyiCommission,

		verification,
	} = props;
	console.log('patientsectin props', props);
	return (
		<>
			<PatientInfo {...props} />
			{verification && (
				<Section title='Verification Info' icon={faUserCheck}>
					<UserVerificationInfo {...verification} />
				</Section>
			)}
			<Section title='Conversations' icon={faComments}>
				<Conversation messages={conversation.messages} />
			</Section>

			<CarePlanSection
				updatedCommissionStatus={updatedCommissionStatus}
				handleUpdateFyiCommission={handleUpdateFyiCommission}
				{...carePlan}
				dischargeProvider={dischargeProvider}
			/>
		</>
	);
};

export const ProviderSection = (props) => {
	const {
		id,
		image_url,
		first_name,
		display_name,
		professional_statement,
		certifications,

		blockedTime,

		payments,

		conversation,
		verification,
		handleVerifyProvider,
		handleUnVerifyProvider,
		admin,
		handleDeletingProvider,
	} = props;

	console.log('ProviderSection', props);
	return (
		<>
			<ColumnFlex alignStart margin='12px 0 0 0'>
				<ImgContainer>
					<FittedImg src={image_url} />
				</ImgContainer>
				<ColumnFlex padding='0 0 0 12px'>
					<ProviderInfo {...props} />
				</ColumnFlex>
			</ColumnFlex>
			<Section title='Professional Statement' icon={faFile}>
				<Text>{professional_statement}</Text>
			</Section>
			{admin && admin.id && (
				<Section title='Delete all data for this provider' icon={faTrash}>
					<Button
						onClick={() => handleDeletingProvider(id)}
						margin='16px  auto'
						backgroundColor={Colors.red}
						width='250px'
					>
						Delete EVERYTHING
					</Button>
				</Section>
			)}
			{verification && (
				<Section title='Verification Info' icon={faUserCheck}>
					<VerificationInfo {...verification} />
				</Section>
			)}
			{verification && (
				<Section
					title={
						verification.admin_verification
							? `Un-Verify ${first_name}`
							: `Verify ${first_name}`
					}
					icon={faUserCheck}
				>
					{!verification.admin_verification ? (
						<Button
							onClick={() => handleVerifyProvider(id)}
							margin='16px 0 0 0'
							backgroundColor={Colors.secondary.green}
							width='250px'
						>{`Verify ${first_name}`}</Button>
					) : (
						<Button
							onClick={() => handleUnVerifyProvider(id)}
							margin='16px 0 0 0'
							backgroundColor={Colors.red}
							width='250px'
						>{`Un-Verify ${first_name}`}</Button>
					)}
				</Section>
			)}

			{certifications && (
				<Section title='Certifications & Screenings' icon={faGraduationCap}>
					{renderCertifications(certifications)}
				</Section>
			)}
			{conversation && (
				<Section icon={faEnvelope} title='Messages'>
					<Conversation messages={conversation.messages} />
				</Section>
			)}

			{blockedTime && (
				<Section title='Blocked Time' icon={faTimes}>
					{blockedTime.map(GenericSessionSection)}
				</Section>
			)}

			<Section title='Payments' icon={faDollarSign}>
				{payments &&
					payments.map((d, i) => {
						return (
							<Section title={moment.unix(d.timestamp).format('MM/DD/YYYY')}>
								<PaymentInfo {...d} />
							</Section>
						);
					})}
			</Section>
		</>
	);
};

export const AdminReportsSection = () => {
	return <ReportSectionViews />;
};

import React from 'react';

import {
	StyledTagsWrapper,
	StyledFilterLabelWrapper,
	StyledLabel,
	StyledFilterTab,
	StyledFilterTabText,
} from './styles';

const Tags = (props) => {
	const { name, label, options, filterHandler } = props;

	return (
		<StyledTagsWrapper>
			<StyledFilterLabelWrapper>
				<StyledLabel>{label}:</StyledLabel>
			</StyledFilterLabelWrapper>

			{options.map((option) => {
				return (
					<StyledFilterTab
						key={option.name}
						selected={option.selected}
						onClick={() => filterHandler(name, option.name, option.value)}
					>
						<StyledFilterTabText selected={option.selected}>
							{option.name}
						</StyledFilterTabText>
					</StyledFilterTab>
				);
			})}
		</StyledTagsWrapper>
	);
};

export default Tags;

import React, { useState } from 'react';

import PageHeading from '../../../../core/components/PageHeading';
import InfoForm from '../../../components/InfoForm';

import { useUpdateSetTraitsMutation } from '../../../api/mutations/useUpdateSetTraitsMutation';
import { useAuth } from '../../../../core/hooks/useAuth';
import { useCarePlan } from '../../../hooks/useCarePlan';

import { StyledInfoPage } from './styles';

const Infopage = () => {
	const [serverError, setServerError] = useState('');

	const updateSetTraitsMutation = useUpdateSetTraitsMutation();
	const { carePlanState, mutateCarePlanState } = useCarePlan();
	const { authUser } = useAuth();

	const infoFormHandler = async (values) => {
		try {
			await updateSetTraitsMutation.mutateAsync({
				setId: authUser.carePlan.carePlanSet.id,
				traits: [
					{ trait_id: 60, value: values.firstName },
					{ trait_id: 61, value: values.lastName },
					{ trait_id: 62, value: values.displayName },
					{ trait_id: 63, value: new Date(values.dob).getDate().toString() },
					{
						trait_id: 64,
						value: new Date(values.dob).toLocaleDateString('default', {
							month: '2-digit',
						}),
					},
					{ trait_id: 65, value: new Date(values.dob).getFullYear().toString() },
					{ trait_id: 66, value: values.gender },
				],
			});

			mutateCarePlanState('info', {
				...carePlanState.info,
				data: {
					firstName: values.firstName,
					lastName: values.lastName,
					displayName: values.displayName,
					dob: values.dob,
					gender: values.gender,
				},
				isCompleted: true,
			});
		} catch (error) {
			console.error('Care plan client info form failed', error);
			setServerError(error[0].message);
		}
	};

	return (
		<StyledInfoPage>
			<PageHeading>Client Information</PageHeading>
			<InfoForm
				initialValues={{
					firstName: carePlanState.info.data.firstName,
					lastName: carePlanState.info.data.lastName,
					displayName: carePlanState.info.data.displayName,
					dob: carePlanState.info.data.dob,
					gender: carePlanState.info.data.gender,
				}}
				infoFormHandler={infoFormHandler}
				serverError={serverError}
			/>
		</StyledInfoPage>
	);
};

export default Infopage;

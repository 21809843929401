import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

const createCarePlanV2 = async ({ userId, relationship }) => {
	try {
		const data = await api.graph({
			query: `mutation {
                createCarePlanV2(
                  relation_to_client: "${relationship}"
                  user_id: ${userId}
                ) {
                  id

				  carePlanSet {
				  	id
				  }

				  careNeedsSet {
				 	id
				  }
                }
              }`,
		});

		return data.createCarePlanV2;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useRelationshipMutation = () => {
	return useMutation({
		mutationKey: ['createCarePlanV2'],
		mutationFn: (values) => {
			return createCarePlanV2(values);
		},
	});
};

import React, { useEffect, useState, createContext } from 'react';
import { useParams } from 'react-router-dom';

import { useFetchSingleJobPostQuery } from '../../api/queries/useFetchSingleJobPostQuery';

export const JobPostContext = createContext();

export const JobPostProvider = ({ children }) => {
	const [hasSetSchedule, setHasSetSchedule] = useState(false);
	const { jobPostId } = useParams();

	const {
		data: jobPost,
		isLoading: isLoadingJobPost,
		refetch: updateJobPost,
	} = useFetchSingleJobPostQuery(jobPostId);

	useEffect(() => {
		if (jobPost) {
			console.log(jobPost);
			if (Array.isArray(jobPost.workTimeFrames) && jobPost.workTimeFrames.length) {
				const hasActiveSchedule = jobPost.workTimeFrames.some(
					(workTimeFrame) => workTimeFrame.active_status_enum === 'active'
				);
				setHasSetSchedule(hasActiveSchedule);
			}
		}
	}, [jobPost]);

	const value = {
		jobPost,
		isLoadingJobPost,
		hasSetSchedule,
		updateJobPost,
	};

	return <JobPostContext.Provider value={value}>{children}</JobPostContext.Provider>;
};

import React from 'react';

import { PrimaryButton, TransparentButton } from '../../../../../corev2/Buttons';
import InformationTile from './InformationTile';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faIdCardAlt, faListAlt } from '@fortawesome/free-solid-svg-icons';

import {
	StyledHomeContainer,
	StyledCopyContainer,
	StyledIntroContainer,
	StyledHeading,
	StyledDescription,
	StyledButtonsWrapper,
	StyledHeroImageContainer,
	StyledHeroImage,
	StyledInformationTilesContainer,
} from './styles';

const Home = () => {
	const heroImImageSrc =
		'https://find-your-independents.s3.amazonaws.com/images/landingImageV2.png';

	return (
		<StyledHomeContainer>
			<StyledCopyContainer>
				<StyledIntroContainer>
					<StyledHeading>Take Back Your Independence</StyledHeading>
					<StyledDescription>
						FYI was created with the goal of providing quality senior care services to
						help our clients remain at home.
					</StyledDescription>

					<StyledButtonsWrapper>
						<PrimaryButton size='small'>Get Started</PrimaryButton>
						<TransparentButton size='small'>Learn More</TransparentButton>
					</StyledButtonsWrapper>
				</StyledIntroContainer>
				<StyledHeroImageContainer>
					<StyledHeroImage src={heroImImageSrc}></StyledHeroImage>
				</StyledHeroImageContainer>
			</StyledCopyContainer>

			<StyledInformationTilesContainer>
				<InformationTile icon={faCopy}></InformationTile>
				<InformationTile icon={faCopy}></InformationTile>
				<InformationTile icon={faCopy}></InformationTile>
			</StyledInformationTilesContainer>
		</StyledHomeContainer>
	);
};

export default Home;

import React from 'react';

import { StyledInformationTile, StyledIcon, StyledTitle, StyledDescription } from './styles';

const InformationTile = (props) => {
	const { icon, title, description } = props;

	return (
		<StyledInformationTile>
			<StyledIcon icon={icon} />
			<StyledTitle>Heading</StyledTitle>
			<StyledDescription>
				Li Europan lingues es membres del sam familie. Lor separat existentie es un myth.
				Por scientie, musica, sport etc, litot
			</StyledDescription>
		</StyledInformationTile>
	);
};

export default InformationTile;

import styled from 'styled-components';

import { colors } from '../../../utils';

export const StyledTagsWrapper = styled.div`
	display: flex;
`;

export const StyledFilterLabelWrapper = styled.div`
	display: flex;
	min-width: 170px;

	text-align: left;
`;

export const StyledLabel = styled.span`
	padding: 5px;
	color: ${colors.gray};
	font-size: 0.8rem;
`;

export const StyledFilterTab = styled.div`
	border: 1px solid ${(props) => (props.selected ? colors.primary.primary : colors.gray)};
	border-radius: 5px;
	display: flex;
	margin: 0 0 0 10px;
	padding: 5px 15px;
	cursor: pointer;
	align-items: center;

	&:hover span {
		color: ${colors.primary.primary};
	}

	&:hover {
		border: 1px solid ${colors.primary.primary};
	}
`;

export const StyledFilterTabText = styled.span`
	font-size: 14px;
	color: ${({ selected }) => (selected ? colors.primary.primary : colors.gray)};
`;

import React from 'react';

import { StyledMain } from './styles';

const Wrapper = (props) => {
	const { children } = props;

	return <StyledMain>{children}</StyledMain>;
};

export default Wrapper;

import styled from 'styled-components';

import { colors } from '../../../../../corev2/utils';

export const StyledMain = styled.main`
	width: 100%;
	min-height: 100vh;
	max-width: 1900px;
	margin: 0 auto;

	background: ${colors.pageBackground};

	padding: 25px 25px 50px 25px;

	display: flex;
	justify-content: center;
`;

import { z } from 'zod';

const logisticsFormValidationSchema = z.object({
	address1: z.string().min(1, 'Address 1 is required'),
	address2: z.string().optional(),
	city: z.string().min(1, 'City is required'),
	zip: z.string().min(5, 'ZIP Code must be at least 5 characters'),
	state: z.string().min(1, 'State is required'),
	codeToEnterHome: z.string().optional(),
	whereToPark: z.string().optional(),
	extraInfo: z.string().optional(),
});

export default logisticsFormValidationSchema;

import React from 'react';
import { useHistory } from 'react-router-dom/';

import InfoPage from './InfoPage';
import PersonalPage from './PersonalPage';
import LogisticsPage from './LogisticsPage';
import CareNeedsPage from './CareNeedsPage';

import PageHeading from '../../../core/components/PageHeading';

import CarePlanItem from '../../components/CarePlanItem';

import { carePlanRoutes } from '../../routes/constants';

import {
	StyledCarePlanManagementPage,
	StyledHeadingContainer,
	StyledDescription,
	StyledCarePlanItemsContainer,
} from './styles';

const carePlanItems = [
	{
		title: 'Client Information',
		description: 'Information related to your name and date of birth.',
		route: carePlanRoutes.management.info,
	},
	{
		title: 'Personal Information',
		description: 'Information such as care requirements, emergency contact information, etc.',
		route: carePlanRoutes.management.personal,
	},
	{
		title: 'Logistics',
		description:
			'Modify your address information so CarePros can get to you on time to deliver quality care.',
		route: carePlanRoutes.management.logistics,
	},
	{
		title: 'Care Needs',
		description: 'A detailed overview of your care needs',
		route: carePlanRoutes.management.careNeeds,
	},
];

const CarePlanManagementPage = (props) => {
	const history = useHistory();

	const goToPage = (route) => {
		history.push(route);
	};

	return (
		<StyledCarePlanManagementPage>
			<StyledHeadingContainer>
				<PageHeading>Manage Your Care Plan</PageHeading>
			</StyledHeadingContainer>

			<StyledDescription>
				Welcome to your personalized care plan management page. Here, you can update and
				review important information to ensure that your care needs are met efficiently and
				effectively. Below is an overview of the sections available for you to manage:
			</StyledDescription>

			<StyledCarePlanItemsContainer>
				{carePlanItems.map((carePlanItem, index) => (
					<CarePlanItem
						key={index}
						title={carePlanItem.title}
						description={carePlanItem.description}
						onClickHandler={() => goToPage(carePlanItem.route)}
					/>
				))}
			</StyledCarePlanItemsContainer>
		</StyledCarePlanManagementPage>
	);
};

export { InfoPage, PersonalPage, LogisticsPage, CareNeedsPage };
export default CarePlanManagementPage;

import React from 'react';

import Select from './Select';
import Tags from './Tags';
import Slider from './Slider';
import TextInput from './TextInput';

import { StyledContainer } from './styles';

const DynamicForm = (props) => {
	const { filters, filterHandler } = props;

	return (
		<StyledContainer>
			{Object.keys(filters).map((filter) => {
				switch (filters[filter].type) {
					case 'tags':
						return (
							<Tags
								name={filters[filter].name}
								label={filters[filter].label}
								options={filters[filter].options}
								filterHandler={filterHandler}
							/>
						);
					case 'drop-down':
						return (
							<Select
								name={filters[filter].name}
								label={filters[filter].label}
								options={filters[filter].options}
								filterHandler={filterHandler}
								customValue={filters[filter].customValue}
							/>
						);
					case 'slider':
						return (
							<Slider
								name={filters[filter].name}
								label={filters[filter].label}
								minAllow={filters[filter].minAllow}
								maxAllow={filters[filter].maxAllow}
								minValue={filters[filter].minValue}
								maxValue={filters[filter].maxValue}
								step={filters[filter].step}
								filterHandler={filterHandler}
							/>
						);
					case 'text':
						return (
							<TextInput
								name={filters[filter].name}
								label={filters[filter].label}
								customValue={filters[filter].customValue}
								filterHandler={filterHandler}
							/>
						);
				}
			})}
		</StyledContainer>
	);
};

export default DynamicForm;

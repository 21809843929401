import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import AnimateHeight from 'react-animate-height';
import { isMobile } from 'react-device-detect';
import { ColumnFlex, RowFlex, Avatar } from 'basicStyles';
import Colors from 'colors';
import Title from 'AppComp/Title';
import Text from 'AppComp/Text';
import Icon from 'AppComp/Icon';
import StyledTextInput from 'AppComp/styled/StyledTextInput';

export const validatePatientProfileInfo = (values) => {
	const errors = {};
	const err = 'Required';

	if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		errors.email = 'Invalid email address';
	}

	if (!values.email) {
		errors.email = err;
	}

	if (!values.phone_number) {
		errors.phone_number = err;
	}

	return errors;
};

import { FormInputCon, CusotmFieldSet, CustomFormButton, CurosrRow } from './PatientSettingViews';

const PatientSettingHandler = (props) => {
	const { updatePatient, patient } = props;

	const [editProfileInfo, setEditProfileInfo] = useState(false);
	const [error, setError] = useState(null);

	const handleSubmit = (values, form) => {
		const params = {
			email: values.email,
			phone_number: values.phone_number,
			address: '',
		};

		updatePatient(params);

		setEditProfileInfo(false);
	};

	const renderInitialValues = () => {
		const initialValues = {
			email: patient.email,
			phone_number: patient.phone_number,
		};

		return initialValues;
	};

	return (
		<ColumnFlex padding={isMobile ? '12px' : '32px'}>
			<Title color={Colors.secondary.black} margin='0 0 18px 0' subtitle>
				Welcome Back
			</Title>
			<Text darkGray mediumText>
				This is the Settings section for patients such as yourself. To edit any of the data
				listed below including changing payment methods. Please press the pencil button to
				the top right to update the fields
			</Text>
			<RowFlex alignCenter spaceBetween margin='32px 0 32px 0 '>
				<Text color={Colors.secondary.blue} largeText>
					Profile Information
				</Text>
				<CurosrRow alignCenter onClick={() => setEditProfileInfo(!editProfileInfo)}>
					<Avatar
						height='30px'
						width='30px'
						color={editProfileInfo ? Colors.secondary.blue : Colors.gray}
					>
						<Icon icon={faPencilAlt} size='sm' />
					</Avatar>
					<Text
						color={editProfileInfo ? Colors.secondary.blue : Colors.gray}
						subtitle
						margin='0 0 0 12px'
					>
						Edit Information
					</Text>
				</CurosrRow>
			</RowFlex>
			<Formik
				onSubmit={handleSubmit}
				initialValues={renderInitialValues()}
				validate={validatePatientProfileInfo}
			>
				{(formProps) => (
					<Form noValidate>
						<CusotmFieldSet
							disabled={!editProfileInfo}
							disabledStatus={!editProfileInfo}
						>
							<RowFlex center>
								<FormInputCon width={isMobile ? '280px' : '360px'}>
									<Field
										component={StyledTextInput}
										name='email'
										label='Email'
										type='text'
									/>
								</FormInputCon>
							</RowFlex>
							<RowFlex center>
								<FormInputCon width={isMobile ? '280px' : '360px'}>
									<Field
										component={StyledTextInput}
										name='phone_number'
										autoComplete='off'
										label='Phone Number'
										type='text'
									/>
								</FormInputCon>
							</RowFlex>
							<RowFlex center>{editProfileInfo}</RowFlex>
							<AnimateHeight duration={250} height={editProfileInfo ? 'auto' : '0%'}>
								<CustomFormButton
									backgroundColor={Colors.secondary.blue}
									type='submit'
									valid={formProps.isValid}
								>
									Update Profile
								</CustomFormButton>
							</AnimateHeight>
						</CusotmFieldSet>
					</Form>
				)}
			</Formik>
		</ColumnFlex>
	);
};

export default PatientSettingHandler;

import React from 'react';

import {
	StyledTextInputWrapper,
	StyledFilterLabelWrapper,
	StyledLabel,
	StyledCustomInput,
} from './styles';

const TextInput = (props) => {
	const { name, label, customValue, filterHandler } = props;

	return (
		<StyledTextInputWrapper center>
			<StyledFilterLabelWrapper>
				<StyledLabel padding='5px'>{label}:</StyledLabel>
			</StyledFilterLabelWrapper>
			<StyledCustomInput
				type='text'
				placeholder='Enter value'
				value={customValue}
				selected={!!customValue}
				onChange={(event) => filterHandler(name, null, null, event.target.value)}
			/>
		</StyledTextInputWrapper>
	);
};

export default TextInput;

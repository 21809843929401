import styled from 'styled-components';

import { colors } from '../../../../../corev2/utils';

export const StyledCarePlanItem = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 8px;
	border: 1px solid ${colors.primary.primary};
	border-radius: 8px;
	padding: 20px;
	background-color: ${colors.white};
	text-align: center;
	cursor: pointer;
`;

export const StyledTitle = styled.h3`
	font-size: 15px;

	font-weight: bolder;

	color: ${colors.primary.primary};
`;

export const StyledDescription = styled.p`
	font-size: 14px;
	color: ${colors.primary.primary};
`;

import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

const addWorkTimeFrameToJobPost = async ({
	startTime,
	endTime,
	carePlanId,
	repeatEvery,
	jobPostId,
}) => {
	try {
		const data = await api.graph({
			query: `mutation {
                addWorkTimeFrameToJobPost(
                    start_time: "${startTime}",
                    end_time: "${endTime}",
                    care_plan_id: ${carePlanId},
						repeat_every_id: "${repeatEvery}"
						job_post_id: ${jobPostId}
                    ) {
                    id
                }
            }`,
		});

		return data.addWorkTimeFrameToJobPost;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useAddWorkTimeFrameToJobPost = () => {
	return useMutation({
		mutationKey: ['addWorkTimeFrameToJobPost'],
		mutationFn: (values) => {
			return addWorkTimeFrameToJobPost(values);
		},
	});
};

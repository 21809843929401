import React from 'react';
import moment from 'moment';

import { SetTableContainer } from '../../admin/ManageDisputes/AdminDisputesView';
import SetTable from 'AppComp/SetTable';
import Text from 'AppComp/Text';

const PayoutsTable = (props) => {
	const { payouts } = props;

	const renderLegend = () => {
		return (
			<>
				<SetTable.ColumnCon>
					<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
						Date
					</Text>
				</SetTable.ColumnCon>
				<SetTable.ColumnCon>
					<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
						Amount
					</Text>
				</SetTable.ColumnCon>
				<SetTable.ColumnCon>
					<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
						Status
					</Text>
				</SetTable.ColumnCon>
				<SetTable.ColumnCon>
					<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
						Failure Code
					</Text>
				</SetTable.ColumnCon>
				<SetTable.ColumnCon>
					<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
						Type
					</Text>
				</SetTable.ColumnCon>
			</>
		);
	};

	const renderRows = () => {
		return payouts.map((d, i) => {
			console.log('d.timesteamp', d.created_at);
			return (
				<SetTable.RowAction key={'ptc-' + d.id}>
					<SetTable.ColumnCon>
						<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
							{moment.unix(d.created_at).format('MM/DD/YYYY')}
						</Text>
					</SetTable.ColumnCon>
					<SetTable.ColumnCon>
						<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
							${d.amount / 100}
						</Text>
					</SetTable.ColumnCon>
					<SetTable.ColumnCon>
						<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
							{d.status ? d.status : 'N/A'}
						</Text>
					</SetTable.ColumnCon>
					<SetTable.ColumnCon>
						<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
							{d.failure_code ? d.failure_code : 'N/A'}
						</Text>
					</SetTable.ColumnCon>
					<SetTable.ColumnCon>
						<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
							{d.type ? d.type : 'N/A'}
						</Text>
					</SetTable.ColumnCon>
				</SetTable.RowAction>
			);
		});
	};

	return (
		<SetTableContainer>
			<SetTable
				title='Payouts'
				renderLegend={renderLegend}
				renderRows={renderRows}
				leftPadding='16px'
			/>
		</SetTableContainer>
	);
};

export default PayoutsTable;

import styled from 'styled-components';

import { colors } from '../../../../../corev2/utils';

export const StyledBanner = styled.div`
	display: flex;
	justify-content: center;
	background: ${colors.primary.primary};
	padding: 5px 0;

	font-size: 14px;
	font-weight: 400;
	color: #ffffff;
`;

export const StyledHeader = styled.header`
	width: 100%;
	height: auto;
	background-color: white;

	position: relative;

	display: flex;
	box-shadow: 0 3px 6px 0 rgba(43, 105, 163, 0.1);
`;

export const StyledWrapper = styled.div`
	width: 100%;
	max-width: 1900px;
	margin: auto;

	padding: 0 25px;

	display: flex;
	justify-content: space-between;
`;

export const StyledLogo = styled.img`
	width: 60px;
	height: 60px;
	cursor: pointer;
`;

export const StyledNavButtonsWrapper = styled.div`
	display: flex;
	align-items: center;
`;

export const StyledNavText = styled.span`
	font-size: 14px;
	line-height: 16px;
	color: ${colors.primary.primary};
`;

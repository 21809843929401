import React, { useState } from 'react';

import PageHeading from '../../../../core/components/PageHeading';
import AccountInfoForm from '../../../components/AccountInfoForm';

import { useOnboarding } from '../../../hooks/useOnboarding';
import { useVerifyUserPhoneMutation } from '../../../api/mutations/useVerifyUserPhoneMutation';

import { StyledAccountInfoPage } from './styles';

const AccountInfoPage = () => {
	const [serverError, setServerError] = useState('');

	const { onboardingState, mutateOnboardingState } = useOnboarding();
	const verifuUserPhoneMutation = useVerifyUserPhoneMutation();

	const accountInfoHandler = async (values) => {
		try {
			await verifuUserPhoneMutation.mutateAsync(values);

			mutateOnboardingState('accountInfo', {
				...onboardingState.accountInfo,
				data: {
					email: values.email,
					phone: values.phone,
					password: values.password,
				},
				isCompleted: true,
			});
		} catch (error) {
			setServerError(error[0].message);
			console.error('Phone / email verification failed', error);
		}
	};

	return (
		<StyledAccountInfoPage>
			<PageHeading>Account Information</PageHeading>

			<AccountInfoForm
				initialValues={{
					email: onboardingState.accountInfo.data.email,
					phone: onboardingState.accountInfo.data.phone,
					password: onboardingState.accountInfo.data.password,
					confirmPassword: '',
				}}
				accountInfoHandler={accountInfoHandler}
				serverError={serverError}
			/>
		</StyledAccountInfoPage>
	);
};

export default AccountInfoPage;

import styled from 'styled-components';

import { colors } from '../../../utils';

export const StyledSliderWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const StyledFilterLabelWrapper = styled.div`
	display: flex;
	min-width: 170px;

	text-align: left;
`;

export const StyledLabel = styled.span`
	padding: 5px;
	color: ${colors.gray};
	font-size: 0.8rem;
`;

export const StyledRangeSelectorWrapper = styled.div`
	width: 80%;

	display: flex;
	flex-direction: column;

	margin: 0.75rem auto 0 auto;
`;

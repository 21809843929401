import React, { useEffect, useState } from 'react';
import {
	useStripe,
	useElements,
	CardNumberElement,
	CardCvcElement,
	CardExpiryElement,
} from '@stripe/react-stripe-js';
import './CardSelectionStyles.css';
import styled from 'styled-components';
import { Hearts } from 'react-loader-spinner';

import Colors from 'colors';

//StyledMediaQuery

const size = {
	mobileS: '320px',
	mobileM: '375px',
	mobileL: '425px',
	tablet: '768px',
	laptop: '1024px',
	laptopL: '1440px',
	desktop: '1860px',
	desktopL: '2560px',
};

export const device = {
	mobileS: `(min-width: ${size.mobileS})`,
	mobileM: `(min-width: ${size.mobileM})`,
	mobileL: `(min-width: ${size.mobileL})`,
	tablet: `(min-width: ${size.tablet})`,
	laptop: `(min-width: ${size.laptop})`,
	laptopL: `(min-width: ${size.laptopL})`,
	desktop: `(min-width: ${size.desktop})`,
	desktopL: `(min-width: ${size.desktopL})`,
};

import Text from 'AppComp/Text';
import FormButton from 'AppComp/FormButton';
import { ColumnFlex, RowFlex } from 'basicStyles';

export const CustomFormButton = styled(FormButton)`
	${({ valid }) =>
		!valid &&
		`
		background-color: white;
		border: 1px solid ${Colors.secondary.blue};
		color: ${Colors.secondary.blue};

	`}

	${({ valid }) =>
		valid &&
		`
		background-color: ${Colors.secondary.blue};
		color: white;
	`}
`;

const CustomColumn = styled(ColumnFlex)`
	width: 100%;

	@media ${device.tablet} {
		width: 340px;

`;

export const CardSection = ({ addPaymentMethodFormValidityHandler }) => {
	const [cardNumberIsValid, setCardNumberIsValid] = useState(false);
	const [cardExpiryIsValid, setCardExpiryIsValid] = useState(false);
	const [cardCvcIsValid, setCardCvcIsValid] = useState(false);

	// Change form validity state based on the validity of
	// CardNumberElement, CardExpiryElement & CardCVCElement

	useEffect(() => {
		addPaymentMethodFormValidityHandler(
			cardNumberIsValid && cardExpiryIsValid && cardCvcIsValid
		);
	}, [cardNumberIsValid, cardExpiryIsValid, cardCvcIsValid]);

	// Only change states if the incoming event.complete
	// is different from current state.
	const cardNumberHandler = (event) => {
		if (cardNumberIsValid !== event.complete) {
			setCardNumberIsValid(event.complete);
		}
	};

	// Only change states if the incoming event.complete
	// is different from current state.
	const cardExpiryHandler = (event) => {
		if (cardExpiryIsValid !== event.complete) {
			setCardExpiryIsValid(event.complete);
		}
	};

	// Only change states if the incoming event.complete
	// is different from current state.
	const cardCvcHandler = (event) => {
		if (cardCvcIsValid !== event.complete) {
			setCardCvcIsValid(event.complete);
		}
	};

	return (
		<CustomColumn margin='0 0 16px 0 '>
			{/* <label>
				Card details
				<CardElement style={{ base: { fontSize: '22px' } }} />
			</label> */}
			<Text margin='12px 0 8px 0'>Card number</Text>
			<CardNumberElement onChange={cardNumberHandler} />
			<Text margin='12px 0 8px 0'>Expiration date</Text>
			<CardExpiryElement onChange={cardExpiryHandler} />
			<Text margin='12px 0 8px 0'>CVC</Text>
			<CardCvcElement onChange={cardCvcHandler} />
		</CustomColumn>
	);
};

export const MagicStuff = ({
	userStripe,
	loading,
	err,
	updateErr,
	updateLoader,
	userId,
	updatePatient,
}) => {
	const stripe = useStripe();
	const elements = useElements();
	const [addPaymentMethodFormIsValid, setAddPaymentMethodFormIsValid] = useState(false);

	const addPaymentMethodFormValidityHandler = (validity) => {
		setAddPaymentMethodFormIsValid(validity);
	};

	const handleSubmit = async (event) => {
		// We don't want to let default form submission happen here,
		// which would refresh the page.
		event.preventDefault();
		updateLoader(true);
		if (!stripe || (!elements && userStripe)) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		const result = await stripe.confirmCardSetup(userStripe, {
			payment_method: {
				card: elements.getElement(CardNumberElement),
				billing_details: {},
			},
		});
		updateLoader(false);
		if (result.error) {
			// Display result.error.message in your UI.
			updateErr(result.error.message);
		} else {
			updatePatient({
				payment_method_verification: 'verified',
			});
		}
	};

	return (
		<ColumnFlex center>
			<form onSubmit={handleSubmit}>
				<CardSection
					addPaymentMethodFormValidityHandler={addPaymentMethodFormValidityHandler}
				/>
				<RowFlex center>
					{/* <button disabled={!stripe}>Save Card</button> */}
					{loading && <Hearts color={Colors.primary.primary} height={50} width={50} />}
					{!loading && (
						<CustomFormButton
							width='200px'
							valid={addPaymentMethodFormIsValid}
							backgroundColor={Colors.teal}
							type='submit'
						>
							Add Payment Method
						</CustomFormButton>
					)}
				</RowFlex>
			</form>
		</ColumnFlex>
	);
};

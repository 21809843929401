import React from 'react';

import PageHeading from '../../../core/components/PageHeading';

import LEGACY_JobPostSchedule from '../../components/LEGACY_ClientSchedule';

import { JobPostProvider } from '../../context/JobPostContext';

import { useAuth } from '../../../core/hooks/useAuth';
import { useJobPost } from '../../hooks/useJobPost';

import { useAddWorkTimeFrameToJobPost } from '../../api/mutations/useAddWorkTimeFrameToJobPost';
import { useUpdateWorkTimeFrame } from '../../api/mutations/useUpdateWorkTimeFrame';
import { useRemoveWorkTimeFrame } from '../../api/mutations/useRemoveWorkTimeFrame';

import { StyledJobSchedulePage } from './styles';

const JobSchedulePageContent = () => {
	const { authUser } = useAuth();
	const { jobPost, updateJobPost, isLoadingJobPost } = useJobPost();

	const addWorkTimeFrameToJobPost = useAddWorkTimeFrameToJobPost();
	const updateWorkTimeFrame = useUpdateWorkTimeFrame();
	const removeWorkTimeFrame = useRemoveWorkTimeFrame();

	const addScheduleToJobPost = async (startTime, endTime, repeatEvery) => {
		try {
			await addWorkTimeFrameToJobPost.mutateAsync({
				startTime,
				endTime,
				carePlanId: authUser.carePlan.id,
				jobPostId: jobPost.id,
				repeatEvery: repeatEvery,
			});

			updateJobPost();
		} catch (error) {
			console.error('Add schedule to job post failed', error);
		}
	};

	const updateSchedule = async (startTime, endTime, workTimeFrameId) => {
		try {
			await updateWorkTimeFrame.mutateAsync({
				startTime,
				endTime,
				workTimeFrameId,
			});

			updateJobPost();
		} catch (error) {
			console.error('Update work time frame failed', error);
		}
	};

	const removeSchedule = async (workTimeFrameId) => {
		try {
			await removeWorkTimeFrame.mutateAsync({
				workTimeFrameId,
			});

			updateJobPost();
		} catch (error) {
			console.error('Remove work time frame failed', error);
		}
	};

	if (isLoadingJobPost) {
		return <>Loading Job Post</>;
	}

	return (
		<StyledJobSchedulePage>
			<PageHeading>Schedule for: {jobPost.title}</PageHeading>
			<LEGACY_JobPostSchedule
				patient={authUser}
				jobPost={jobPost}
				carePlan={authUser.carePlan}
				addScheduleToJobPost={addScheduleToJobPost}
				updateSchedule={updateSchedule}
				removeSchedule={removeSchedule}
			/>
		</StyledJobSchedulePage>
	);
};

const JobSchedulePage = () => {
	return (
		<JobPostProvider>
			<JobSchedulePageContent />
		</JobPostProvider>
	);
};

export default JobSchedulePage;

import { z } from 'zod';

const infoFormValidationSchema = z.object({
	firstName: z.string().min(1, 'First name is required'),
	lastName: z.string().min(1, 'Last name is required'),
	displayName: z.string().min(1, 'Display name is required'),
	dob: z.string().nonempty('Date of birth is required'),
	gender: z.enum(['male', 'female'], 'Gender is required'),
});

export default infoFormValidationSchema;

import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { receiveUserDetails } from 'reducers/admin';

import AdminChat from './AdminChat';
import AdminDisputesHandler from './ManageDisputes/AdminDisputesHandler';
import CarePlanSearch from './CarePlanSearch';
import PaymentTable from './PaymentTable';
import ProviderSearch from './ProviderSearch';
import UserModel from './UserModel';
import AllShifts from './AllShifts';
import OnboardFlow from './OnboardFlow';
import AgencySettings from './AgencySettings';
import ManageDocuments from './ManageDocuments';
import ManageDoc from './ManageDoc';
import ProviderPage from './ProviderPage';
import ShiftPage from './ShiftPage';
import PaymentPage from './PaymentPage';
import PatientSearch from './PatientSearch';
import ClientPage from './ClientPage';
import CareProProfile from './CarePro/CareProHandler';
import CareProDocs from './CarePro/ManageCareProDocs';

import BasicOverlayWrapper from 'AppComp/BasicOverlayWrapper';

import { RowFlex } from '../styled/Basic';

const CustomRow = styled(RowFlex)`
	overflow: hidden;
`;

const AdminRoutes = (props) => {
	const { userDetail, receiveUserDetails } = props;

	return (
		<CustomRow flex>
			<BasicOverlayWrapper
				status={userDetail.status}
				appear
				handleExitClick={() =>
					receiveUserDetails({ status: false, user: null, type: null })
				}
			>
				<UserModel
					userDetail={userDetail}
					handleExitClick={() =>
						receiveUserDetails({ status: false, user: null, type: null })
					}
				/>
			</BasicOverlayWrapper>
			<Switch>
				<Route
					path='/admin'
					exact
					children={(props) => {
						return <PaymentTable {...props} />;
					}}
				/>
				<Route
					path='/admin/shifts'
					exact
					children={(props) => {
						return <AllShifts {...props} />;
					}}
				/>
				<Route
					path='/admin/shift/:shift_id'
					exact
					children={(props) => {
						return <ShiftPage {...props} />;
					}}
				/>
				<Route
					path='/admin/payment/:payment_id'
					exact
					children={(props) => {
						return <PaymentPage {...props} />;
					}}
				/>

				<Route
					path='/admin/messages/:conversation_id'
					children={(props) => {
						return <AdminChat {...props} />;
					}}
				/>
				<Route
					path='/admin/messages'
					children={(props) => {
						return <AdminChat {...props} />;
					}}
				/>
				<Route
					exact
					path='/admin/disputes'
					children={(props) => {
						return <p>Show the right screen</p>;
					}}
				/>
				<Route
					exact
					path='/admin/carepro/:careProId'
					children={(props) => {
						return <CareProProfile {...props} />;
					}}
				/>
				<Route
					exact
					path='/admin/careproDocs/:careProId'
					children={(props) => {
						return <CareProDocs {...props} />;
					}}
				/>

				<Route
					exact
					path='/admin/carepros'
					children={(props) => {
						return <ProviderSearch {...props} />;
					}}
				/>
				<Route
					exact
					path='/admin/clients/:clientId'
					children={(props) => {
						return <ClientPage {...props} />;
					}}
				/>
				<Route
					exact
					path='/admin/clients'
					children={(props) => {
						return <PatientSearch {...props} />;
					}}
				/>
				<Route
					exact
					path='/admin/careplans'
					children={(props) => {
						return <CarePlanSearch {...props} />;
					}}
				/>

				<Route
					exact
					path='/admin/onboard'
					children={(props) => {
						return <OnboardFlow {...props} />;
					}}
				/>
				<Route
					exact
					path='/admin/settings'
					children={(props) => {
						return <AgencySettings {...props} />;
					}}
				/>
				<Route
					path='/admin/settings/:tab'
					children={(props) => {
						return <AgencySettings {...props} />;
					}}
				/>
				<Route
					path='/admin/documents'
					children={(props) => {
						return <ManageDocuments {...props} />;
					}}
				/>
				<Route
					path='/admin/documents/:type'
					children={(props) => {
						return <ManageDocuments {...props} />;
					}}
				/>

				<Route
					path='/admin/document/:id'
					children={(props) => {
						return <ManageDoc {...props} />;
					}}
				/>
			</Switch>
		</CustomRow>
	);
};

const mapStateToProps = ({ admin }) => {
	return {
		userDetail: admin.userDetail,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		receiveUserDetails: (params) => dispatch(receiveUserDetails(params)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminRoutes);

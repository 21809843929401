import styled from 'styled-components';
import { motion } from 'framer-motion';

import { colors } from '../../utils';

export const StyledContainer = styled.div`
	background: white;
	display: flex;
	height: fit-content;
	width: fit-content;
	z-index: 100;
	left: 0;
	display: flex;
	flex-direction: column;
	top: 0;
	border: 1px solid ${colors.primary.primary};
	border-radius: 5px;
	gap: 15px;

	padding: 30px 25px;
	overflow: auto;
`;

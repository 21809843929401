import styled from 'styled-components';

import { colors } from '../../utils';

export const StyledTransparentButton = styled.button`
	border-radius: 24px;
	padding: ${({ size }) => (size === 'small' ? '0.5em 1em' : '0.6rem 1em')};
	background: none;
	border: none;
	letter-spacing: 0.5px;
	text-align: center;
	font-size: ${({ size }) => (size === 'small' ? '0.875em' : '1.1em')};
	line-height: 1.7rem;
	color: ${colors.primary.primary};
`;

export const filterParser = (serializer, filters, name, option, selectValue, customInput) => {
	let updatedFilters = filters;
	let newFilter = updatedFilters[name];
	let newFilterOption;
	let newFilterOptionsList;
	let newOptionIndex;

	switch (newFilter.type) {
		case 'tags':
			newFilterOption = newFilter.options.filter(
				(iteratedOption) => iteratedOption.name === option
			);

			newFilterOption = newFilterOption[0];
			newFilterOptionsList = newFilter.options;
			newFilterOption = {
				...newFilterOption,
				selected: !newFilterOption.selected,
			};

			newOptionIndex = newFilter.options.findIndex(
				(iteratedOption) => iteratedOption.name === newFilterOption.name
			);

			newFilterOptionsList[newOptionIndex] = newFilterOption;
			newFilter = { ...newFilter, options: newFilterOptionsList };
			updatedFilters = { ...updatedFilters, [name]: newFilter };
			break;

		case 'drop-down':
			if (customInput !== undefined && customInput !== '') {
				newFilter = {
					...newFilter,
					customValue: customInput,
				};

				updatedFilters = { ...updatedFilters, [name]: newFilter };
				break;
			}

			newFilterOption = newFilter.options[option].filter((iteratedOption) => {
				return iteratedOption.name === selectValue;
			});

			newFilterOption = newFilterOption[0];
			newFilterOptionsList = newFilter.options[option];
			newFilterOption = {
				...newFilterOption,
				selected: !newFilterOption.selected,
			};

			newOptionIndex = newFilter.options[option].findIndex(
				(iteratedOption) => iteratedOption.name === newFilterOption.name
			);

			const tempNewFilterOptionsList = newFilterOptionsList.map((option) => {
				return { ...option, selected: false };
			});

			tempNewFilterOptionsList[newOptionIndex] = newFilterOption;

			newFilter = {
				...newFilter,
				options: {
					...newFilter.options,
					[option]: tempNewFilterOptionsList,
				},
			};

			updatedFilters = { ...updatedFilters, [name]: newFilter };
			break;

		case 'text':
			newFilter = {
				...newFilter,
				customValue: customInput,
			};

			updatedFilters = { ...updatedFilters, [name]: newFilter };
			break;

		case 'slider':
			newFilter = {
				...newFilter,
				minValue: selectValue.minValue,
				maxValue: selectValue.maxValue,
				active: true,
			};

			updatedFilters = { ...updatedFilters, [name]: newFilter };
	}

	return { updatedFilters, parsedData: serializer(updatedFilters) };
};

export const serializer = (filters) => {
	const result = [];

	for (const key in filters) {
		const field = filters[key];

		switch (field.type) {
			case 'drop-down':
				field.options.default.forEach((option) => {
					if (option.selected) {
						if (option.value === 'custom') {
							result.push({
								trait_id: option.traitId,
								value: field.customValue,
							});
						}
						if (option.value !== 'default') {
							result.push({
								trait_id: option.traitId,
								value: option.value,
							});
						}
					} else {
						if (option.value !== 'default') {
							result.push({
								trait_id: option.traitId,
								value: 'null',
							});
						}
					}
				});
				break;

			case 'text':
				result.push({
					trait_id: field.traitId,
					value: field.customValue,
				});
				break;

			case 'tags':
				field.options.forEach((option) => {
					result.push({
						trait_id: option.traitId,
						value: option.selected.toString(),
					});
				});
				break;

			default:
				break;
		}
	}

	return result;
};

export const parser = (filters) => {
	const result = [];

	for (const key in filters) {
		const field = filters[key];

		switch (field.type) {
			case 'drop-down':
				field.options.default.forEach((option) => {
					if (option.selected) {
						if (option.value === 'custom') {
							result.push({
								trait_id: option.traitId,
								value: field.customValue,
							});
						}
						if (option.value !== 'default') {
							result.push({
								trait_id: option.traitId,
								value: option.value,
							});
						}
					} else {
						if (option.value !== 'default') {
							result.push({
								trait_id: option.traitId,
								value: 'null',
							});
						}
					}
				});
				break;

			case 'text':
				result.push({
					trait_id: field.traitId,
					value: field.customValue,
				});
				break;

			case 'tags':
				field.options.forEach((option) => {
					result.push({
						trait_id: option.traitId,
						value: option.selected.toString(),
					});
				});
				break;

			default:
				break;
		}
	}

	return result;
};

export const reverseParser = (data, filters) => {
	const updatedFilters = JSON.parse(JSON.stringify(filters));

	data.forEach((item) => {
		const traitId = item.id;
		const value = item.value ? item.value.trait_value.replace(/\"/g, '') : null;

		for (const key in updatedFilters) {
			const field = updatedFilters[key];

			if (field.type === 'drop-down') {
				field.options.default.forEach((option) => {
					if (option.traitId === traitId) {
						option.selected =
							value === 'null' || value === null ? !!JSON.parse(value) : !!value;

						if (option.value === 'custom') {
							field.customValue = value === 'null' ? '' : value;
						}
					}
				});
			} else if (field.type === 'text') {
				if (field.traitId === traitId) {
					field.customValue = value === 'null' || value === null ? '' : value;
				}
			} else if (field.type === 'tags') {
				field.options.forEach((option) => {
					if (option.traitId === traitId) {
						option.selected = value === 'true';
					}
				});
			}
		}
	});

	return updatedFilters;
};

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import Colors from 'colors';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

import { TOAST_ERROR_CONFIG, TOAST_SUCCESS_CONFIG } from 'constants';

import Title from 'AppComp/Title';
import Text from 'AppComp/Text';
import { TextArea } from 'AppComp/styled/StyledTextInput';

import { ColumnFlex } from 'basicStyles';
import useWindowSize from 'scripts/useWindowSize';
import api from 'scripts/api';

import { StyledTextSelectInputV2 } from 'AppComp/styled/StyledTextInput';
import StyledTextInput, { StrongPasswordTextInput } from 'AppComp/styled/StyledTextInput';

import { CARE_PLAN_ACTIONS } from '../../patient/CarePlan/CarePlanParent';
import {
	FORM_ITEM_LENGTH,
	MARGIN_ITEMS,
	FormItemsLength,
	FormButtonStyled,
} from '../../patient/CarePlan/CarePlanviews';

import { GENDER } from 'constants';
import { USER_MODEL_V2 } from 'reducers/graphqlModelTypes';

const CareProfile = (props) => {
	const { patient, carePlan, moveToStep, receivePatientCarePlan } = props;
	const [carePlanSetId, setCarePlanSetId] = useState([]);
	const [initialValues, setInitialValues] = useState(null);
	const { width } = useWindowSize();
	const { clientId } = useParams();

	useEffect(() => {
		if (clientId) fetchUser();
	}, [clientId]);

	const fetchUser = async () => {
		try {
			const response = await api.graph({
				query: `{
					user(
						id: ${clientId}
					) {
						${USER_MODEL_V2}
					}
				}`,
			});

			console.log(response.user);

			setCarePlanSetId(response.user.carePlan.id);

			// parse the data since it's in json and will mess up the form
			const parsedCarePlanSet = response.user.carePlan.carePlanSet.setData.map((item) => {
				return {
					...item,
					value: item.value
						? {
								...item.value,
								trait_value: JSON.parse(item.value.trait_value),
						  }
						: null,
				};
			});
			setInitialValues(renderInitialValues(parsedCarePlanSet));
		} catch (err) {
			console.log('fetchUserCarePlan -> err', err);
			localStorage.removeItem('carePlanId');
			toast.error('Something went wrong please notify support', TOAST_ERROR_CONFIG);
		}
	};

	const renderInitialValues = (carePlanSet) => {
		console.log('Initial values - careNeedsSet', carePlanSet);
		return {
			firstName: carePlanSet[0].value?.trait_value || null,
			lastName: carePlanSet[1].value?.trait_value || null,
			displayName: carePlanSet[2].value?.trait_value || null,
			dob: `${carePlanSet[5].value?.trait_value}-${String(
				carePlanSet[4].value?.trait_value
			).padStart(2, '0')}-${String(carePlanSet[3].value?.trait_value).padStart(2, '0')}`, // Construct the date string here
			gender: carePlanSet[6].value?.trait_value || null,
			jobStatement: carePlanSet[7].value?.trait_value || null,
			weight: carePlanSet[8].value?.trait_value || null,
			diagnosis: carePlanSet[9].value?.trait_value || null,
			contact1Name: carePlanSet[10].value?.trait_value || null,
			contact1Relationship: carePlanSet[11].value?.trait_value || null,
			contact1Phone: carePlanSet[12].value?.trait_value || null,
			contact2Name: carePlanSet[13].value?.trait_value || null,
			contact2Relationship: carePlanSet[14].value?.trait_value || null,
			contact2Phone: carePlanSet[15].value?.trait_value || null,
			address: carePlanSet[16].value?.trait_value || null,
			address2: carePlanSet[17].value?.trait_value || null,
			city: carePlanSet[18].value?.trait_value || null,
			state: carePlanSet[19].value?.trait_value || null,
			zip: carePlanSet[20].value?.trait_value || null,
			codeToEnterHome: carePlanSet[21].value?.trait_value || null,
			whereToPark: carePlanSet[22].value?.trait_value || null,
			extraInfo: carePlanSet[23].value?.trait_value || null,
		};
	};

	const handleSubmit = (values, form) => {
		console.log('form', form);
		// get the differnce from values and initialValues
		const latestValues = values;
		const initValues = initialValues;

		const diff = Object.keys(latestValues).reduce((acc, key) => {
			if (latestValues[key] !== initValues[key]) {
				// if the value we're deleted and is empty string replace it with null
				if (latestValues[key] === '') {
					acc[key] = null;
				} else acc[key] = latestValues[key];
			}
			return acc;
		}, {});

		console.log('diff', diff);

		const updateParams = [
			{ trait_id: 60, value: values.firstName?.toString() },
			{ trait_id: 61, value: values.lastName?.toString() },
			{ trait_id: 62, value: values.displayName?.toString() },
			{ trait_id: 63, value: new Date(values.dob).getDate().toString() },
			{
				trait_id: 64,
				value: new Date(values.dob).toLocaleDateString('default', {
					month: '2-digit',
				}),
			},
			{ trait_id: 65, value: new Date(values.dob).getFullYear().toString() },
			{ trait_id: 66, value: values.gender?.toString() },
			{ trait_id: 67, value: values?.jobStatement?.toString() },
			{ trait_id: 68, value: values.weight?.toString() },
			{ trait_id: 69, value: values.diagnosis?.toString() },
			{ trait_id: 70, value: values.contact1Name?.toString() },
			{ trait_id: 71, value: values.contact1Relationship?.toString() },
			{ trait_id: 72, value: values.contact1Phone?.toString() },
			{ trait_id: 73, value: values.contact2Name?.toString() },
			{ trait_id: 74, value: values.contact2Relationship?.toString() },
			{ trait_id: 75, value: values.contact2Phone?.toString() },
			{ trait_id: 76, value: values.address?.toString() },
			{ trait_id: 77, value: values.address2?.toString() },
			{ trait_id: 78, value: values.city?.toString() },
			{ trait_id: 79, value: values.zip?.toString() },
			{ trait_id: 80, value: values.state?.toString() },
			{ trait_id: 81, value: values.codeToEnterHome?.toString() },
			{ trait_id: 82, value: values.whereToPark?.toString() },
			{ trait_id: 83, value: values.extraInfo?.toString() },
		];

		updateSetTraits(updateParams);
	};

	const updateSetTraits = async (params) => {
		console.log('Mutating carePlanSetId', carePlanSetId, params);
		try {
			const response = await api.graph({
				query: `mutation {
					adminUpdateSetTraits (
						set_id: ${carePlanSetId},
						traits: ${api.graphStringify(params)},
						user_type: ${api.graphStringify('client')}
					) {
						id
						value {
							trait_value
						}
					}
				}`,
			});

			console.log('Mutation response', response.updateSetTraits);

			if (response.updateSetTraits) {
				toast.success('Care Profile Updated', TOAST_SUCCESS_CONFIG);
			}
		} catch (err) {
			toast.error('Care Profile could not be updated', TOAST_ERROR_CONFIG);
			console.log(err);
		}
	};

	if (!initialValues) {
		return <div>Loading...</div>;
	}

	return (
		<ColumnFlex
			style={{
				height: 'fit-content',
				backgroundColor: 'white',
				borderRadius: '12px',
			}}
			padding={width < 768 ? 0 : '60px 80px'}
			center
			gap='32px'
		>
			<ColumnFlex fullWidth center gap='8px'>
				<Title nubtitle fontWeight='700'>
					Tell us about the person who needs care
				</Title>
			</ColumnFlex>
			<ColumnFlex
				fullWidth
				style={{
					flex: 1,
					borderRadius: '24px',
				}}
				center
			>
				<Formik
					onSubmit={handleSubmit}
					initialValues={initialValues}
					enableReinitialize={true}
					autoComplete={'off'}
					render={(formProps) => (
						<Form noValidate>
							<ColumnFlex alignCenter justifyCenter fullWidth fullHeight>
								<FormItemsLength
									margin='30px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										name='firstName'
										component={StyledTextInput}
										label='First Name *'
										type='text'
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										name='lastName'
										component={StyledTextInput}
										label='Last Name *'
										type='text'
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										name='displayName'
										component={StyledTextInput}
										label='Display Name *'
										type='text'
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										label='Date of Birth*'
										name='dob'
										type='date'
										component={StyledTextInput}
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										name='gender'
										component={StyledTextSelectInputV2}
										label='Gender'
										options={GENDER}
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='30px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										name='jobStatement'
										component={TextArea}
										label='Job Statement'
										type='text'
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										name='weight'
										component={StyledTextInput}
										label='Weight'
										type='number'
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										name='diagnosis'
										component={StyledTextInput}
										label='Diagnosis'
										type='text'
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										label='Emergency Contact 1 - Name'
										name='contact1Name'
										type='text'
										component={StyledTextInput}
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										label='Emergency Contact 1 - Relationship'
										name='contact1Relationship'
										type='text'
										component={StyledTextInput}
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										label='Emergency Contact 1 - Phone'
										name='contact1Phone'
										type='text'
										component={StyledTextInput}
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										label='Emergency Contact 2 - Name'
										name='contact2Name'
										type='text'
										component={StyledTextInput}
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										label='Emergency Contact 2 - Relationship'
										name='contact2Relationship'
										type='text'
										component={StyledTextInput}
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										label='Emergency Contact 2 - Phone'
										name='contact2Phone'
										type='text'
										component={StyledTextInput}
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										label='Logistics - Street Address'
										name='address'
										type='text'
										component={StyledTextInput}
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										label='Logistics - Street Address 2'
										name='address2'
										type='text'
										component={StyledTextInput}
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										label='Logistics - City'
										name='city'
										type='text'
										component={StyledTextInput}
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										label='Logistics - ZIP'
										name='zip'
										type='text'
										component={StyledTextInput}
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										label='Logistics - State'
										name='state'
										type='text'
										component={StyledTextInput}
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										label='Logistics - Code To Enter Home'
										name='codeToEnterHome'
										type='text'
										component={StyledTextInput}
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										label='Logistics - Where To Park'
										name='whereToPark'
										type='text'
										component={TextArea}
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										label='Logistics - Extra Info'
										name='extraInfo'
										type='text'
										component={TextArea}
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormButtonStyled
									backgroundColor={Colors.primary.primary}
									width={'300px'}
									height={'70px'}
									valid={formProps.isValid}
									borderRadius='32px'
									type='submit'
								>
									<Text
										fontWeight='500'
										color={formProps.isValid ? 'white' : Colors.primary.primary}
										largeText
									>
										Save
									</Text>
								</FormButtonStyled>

								<Text
									margin='12px 0 0 0'
									// onClick={() => moveToStep(CARE_PLAN_ACTIONS.CARE_PLAN_HOME)}
									style={{ cursor: 'pointer', marginTop: '16px' }}
									color={Colors.primary.primary}
									largeText
								>
									Back
								</Text>
							</ColumnFlex>
						</Form>
					)}
				/>
			</ColumnFlex>
		</ColumnFlex>
	);
};

export default CareProfile;

import React, { useState } from 'react';

import PageHeading from '../../../../core/components/PageHeading';
import RelationshipForm from '../../../components/RelationshipForm';

import { useRelationshipMutation } from '../../../api/mutations/useRelationshipMutation';
import { useCarePlan } from '../../../hooks/useCarePlan';
import { useAuth } from '../../../../core/hooks/useAuth';

import { StyledRelationshipPage } from './styles';

const RelationshipPage = () => {
	const [serverError, setServerError] = useState('');

	const relationshipMutation = useRelationshipMutation();
	const { carePlanState, mutateCarePlanState } = useCarePlan();
	const { authUser, modifyAuthUser } = useAuth();

	const relationshipHandler = async (values) => {
		try {
			const data = await relationshipMutation.mutateAsync({
				userId: authUser.id,
				relationship: values.relationship,
			});

			modifyAuthUser('carePlan', data);

			mutateCarePlanState('relationship', {
				...carePlanState.relationship,
				data: {
					relationship: values.relationship,
				},
				isCompleted: true,
			});
		} catch (error) {
			setServerError(error[0].message);
			console.error('Login failed', error);
		}
	};

	return (
		<StyledRelationshipPage>
			<PageHeading>Relationship To Client</PageHeading>
			<RelationshipForm
				initialValues={{
					relationship: carePlanState.relationship.data.relationship,
				}}
				relationshipHandler={relationshipHandler}
				serverError={serverError}
			/>
		</StyledRelationshipPage>
	);
};

export default RelationshipPage;

import styled from 'styled-components';

import { colors } from '../utils';

export const StyledRow = styled.div`
	display: flex;
	gap: 5px;

	color: ${colors.primary.primary};
`;

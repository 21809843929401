import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import PageHeading from '../../../../core/components/PageHeading';
import DynamicForm, { serializer, filterParser } from '../../../../../../corev2/DynamicForm';
import { PrimaryButton } from '../../../../../../corev2/Buttons';

import { useAuth } from '../../../../core/hooks/useAuth';
import { useCarePlan } from '../../../hooks/useCarePlan';

import { useHandleUpdateCarePlanPlatformOnboard } from '../../../api/mutations/useHandleUpdateCarePlanPlatformOnboard';
import { useUpdateSetTraitsMutation } from '../../../api/mutations/useUpdateSetTraitsMutation';

import { coreRoutes } from '../../../../core/routes/constants';

import needs from './needs.json';

import { StyledCareNeedsPage, StyledButtonContainer } from './styles';

const CareNeedsPage = () => {
	const [clientNeeds, setClientNeeds] = useState(needs);
	const [traits, setTraits] = useState([]);
	const history = useHistory();

	const { carePlanState, mutateCarePlanState, resetCarePlanState } = useCarePlan();
	const { authUser, modifyAuthUser } = useAuth();
	const updateSetTraitsMutation = useUpdateSetTraitsMutation();
	const handleUpdateCarePlanPlatformOnboard = useHandleUpdateCarePlanPlatformOnboard();

	const careNeedsHandler = useCallback(
		(setId, traits) => {
			const debounce = setTimeout(async () => {
				try {
					await updateSetTraitsMutation.mutateAsync({ setId, traits });
				} catch (error) {
					console.error('Care plan care needs page failed', error);
				}
			}, 1000);

			return () => clearTimeout(debounce);
		},
		[updateSetTraitsMutation]
	);

	const goToNextPage = async () => {
		try {
			const data = await handleUpdateCarePlanPlatformOnboard.mutateAsync({
				carePlanId: authUser.carePlan.id,
			});

			modifyAuthUser('carePlan', data);

			mutateCarePlanState('careNeeds', {
				...carePlanState.careNeeds,
				isCompleted: true,
			});

			resetCarePlanState();

			history.replace(coreRoutes.jobPosts);
		} catch (error) {
			console.error('handleUpdateCarePlanPlatformOnboard failed', error);
		}
	};

	useEffect(() => {
		if (authUser && traits.length) {
			const handler = careNeedsHandler(authUser.carePlan.careNeedsSet.id, traits);
			return () => handler();
		}
	}, [authUser, traits]);

	const searchFilterHandler = (...args) => {
		const { updatedFilters, parsedData } = filterParser(serializer, clientNeeds, ...args);

		setTraits(parsedData);
		setClientNeeds(updatedFilters);
	};

	return (
		<StyledCareNeedsPage>
			<PageHeading>Care Needs</PageHeading>

			<DynamicForm filters={clientNeeds} filterHandler={searchFilterHandler} />

			<StyledButtonContainer>
				<PrimaryButton size='small' onClick={goToNextPage}>
					Next
				</PrimaryButton>
			</StyledButtonContainer>
		</StyledCareNeedsPage>
	);
};

export default CareNeedsPage;

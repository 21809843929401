import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

const updateWorkTimeFrame = async ({ startTime, endTime, workTimeFrameId }) => {
	try {
		const data = await api.graph({
			query: `mutation {
                    updateWorkTimeFrame(
                        start_time: "${startTime}",
                        end_time: "${endTime}",
                        work_time_frame_id: ${workTimeFrameId},
                    ) {
                        id
                    }
            }`,
		});

		return data.updateWorkTimeFrame;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useUpdateWorkTimeFrame = () => {
	return useMutation({
		mutationKey: ['updateWorkTimeFrame'],
		mutationFn: (values) => {
			return updateWorkTimeFrame(values);
		},
	});
};

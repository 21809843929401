import React, { useState } from 'react';

import PageHeading from '../../../../core/components/PageHeading';
import LogisticsForm from '../../../components/LogisticsForm';

import { useUpdateSetTraitsMutation } from '../../../api/mutations/useUpdateSetTraitsMutation';
import { useCarePlan } from '../../../hooks/useCarePlan';
import { useAuth } from '../../../../core/hooks/useAuth';

import { StyledLogisticsPage } from './styles';

const LogisticsPage = () => {
	const [serverError, setServerError] = useState('');

	const updateSetTraitsMutation = useUpdateSetTraitsMutation();
	const { carePlanState, mutateCarePlanState } = useCarePlan();
	const { authUser } = useAuth();

	const logisticsFormHandler = async (values) => {
		try {
			await updateSetTraitsMutation.mutateAsync({
				setId: authUser.carePlan.carePlanSet.id,
				traits: [
					{ trait_id: 76, value: values.address1 },
					{ trait_id: 77, value: values.address2 },
					{ trait_id: 78, value: values.city },
					{ trait_id: 79, value: values.zip },
					{ trait_id: 80, value: values.state },
					{ trait_id: 81, value: values.codeToEnterHome },
					{ trait_id: 82, value: values.whereToPark },
					{ trait_id: 83, value: values.extraInfo },
				],
			});

			mutateCarePlanState('logistics', {
				...carePlanState.logistics,
				data: {
					address1: values.address1,
					address2: values.address2,
					city: values.city,
					zip: values.zip,
					state: values.state,
					codeToEnterHome: values.codeToEnterHome,
					whereToPark: values.whereToPark,
					extraInfo: values.extraInfo,
				},
				isCompleted: true,
			});
		} catch (error) {
			console.error('Care plan logistics form failed', error);
			setServerError(error[0].message);
		}
	};

	return (
		<StyledLogisticsPage>
			<PageHeading>Logistics</PageHeading>

			<LogisticsForm
				initialValues={{
					address1: carePlanState.logistics.data.address1,
					address2: carePlanState.logistics.data.address2,
					city: carePlanState.logistics.data.city,
					zip: carePlanState.logistics.data.zip,
					state: carePlanState.logistics.data.state,
					codeToEnterHome: carePlanState.logistics.data.codeToEnterHome,
					whereToPark: carePlanState.logistics.data.whereToPark,
					extraInfo: carePlanState.logistics.data.extraInfo,
				}}
				logisticsFormHandler={logisticsFormHandler}
				serverError={serverError}
			/>
		</StyledLogisticsPage>
	);
};

export default LogisticsPage;

import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

const handleUpdateCarePlanPlatformOnboard = async ({ carePlanId }) => {
	try {
		const data = await api.graph({
			query: `mutation{
            handleCompleteCarePlanPlatformOnboard(
                care_plan_id: ${carePlanId}
            )
				{
                  id
				  completed_platform_onboard

				  carePlanSet {
				  	id
				  }

				  careNeedsSet {
				 	id
				  }
                }
            }`,
		});

		return data.handleCompleteCarePlanPlatformOnboard;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useHandleUpdateCarePlanPlatformOnboard = () => {
	return useMutation({
		mutationKey: ['handleUpdateCarePlanPlatformOnboard'],
		mutationFn: (values) => {
			return handleUpdateCarePlanPlatformOnboard(values);
		},
	});
};

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

//StyledMediaQuery

const size = {
	mobileS: '320px',
	mobileM: '375px',
	mobileL: '425px',
	tablet: '768px',
	laptop: '1024px',
	laptopL: '1440px',
	desktop: '1860px',
	desktopL: '2560px',
};

export const device = {
	mobileS: `(min-width: ${size.mobileS})`,
	mobileM: `(min-width: ${size.mobileM})`,
	mobileL: `(min-width: ${size.mobileL})`,
	tablet: `(min-width: ${size.tablet})`,
	laptop: `(min-width: ${size.laptop})`,
	laptopL: `(min-width: ${size.laptopL})`,
	desktop: `(min-width: ${size.desktop})`,
	desktopL: `(min-width: ${size.desktopL})`,
};

import { showInfoModel, toggleChangePasswordOverlay } from 'reducers/environment';
import Colors from 'colors';

import PageLoaderView from 'AppComp/PageLoaderView';

import Scroll from 'AppComp/Scroll';
import Button from 'AppComp/Button';

import BasicOverlayWrapper from 'AppComp/BasicOverlayWrapper';
import InvoiceExportModel from 'AppComp/InvoiceExportModel';

import PatientSetting from './PatientSetting';

import PatientCardCollection from './PatientCardCollection';

import { RelativeColumn, CareplanDetailFormView } from './CarePlanDetail/CarePlanDetailView';

export const BasicChildContainer = styled.div`
	padding: 4px;
	margin: auto;
	position: relative;
	height: ${({ height }) => height || 'inherit'};
	z-index: 5;
	@media ${device.mobileS} {
		padding: 24px 4px;
		width: 98vw;
	}
	@media ${device.mobileL} {
		width: 100vw;
		padding: 16px;
	}
	@media ${device.laptop} {
		width: 980px;
	}
	@media ${device.desktopL} {
	}
`;

const PatientProfileHandler = (props) => {
	const {
		patient,
		updateUserHandler,
		logoutUser,
		toggleChangePasswordOverlay,
		savedPaymentMethods,
		deletePaymentMethodHandler,
		updateDefaultPaymentMethodHandler,
		createUserStripeIntentHandler,
	} = props;

	const [showExportInvoiceOverlay, updateExportInvoiceOverlay] = useState(false);

	return (
		<RelativeColumn
			style={{
				backgroundColor: Colors.theme.primary_background,
			}}
			flex
			fullWidth
			alignCenter
		>
			<BasicOverlayWrapper
				status={showExportInvoiceOverlay}
				handleExitClick={() => updateExportInvoiceOverlay(false)}
				appear
			>
				<InvoiceExportModel type='patient' />
			</BasicOverlayWrapper>
			{/* <InfoButton onClick={() => renderInfo()} /> */}

			<Scroll idName='PatientsSearchPage' />

			<BasicChildContainer>
				<CareplanDetailFormView height='auto'>
					{patient !== null && patient.id ? (
						<>
							<PatientSetting
								patient={patient}
								updatePatient={updateUserHandler}
								logoutUser={logoutUser}
								savedPaymentMethods={savedPaymentMethods}
								toggleChangePasswordOverlay={toggleChangePasswordOverlay}
							/>
						</>
					) : (
						<PageLoaderView errorMessage='Not signed in' />
					)}
				</CareplanDetailFormView>
			</BasicChildContainer>
			<BasicChildContainer>
				<CareplanDetailFormView height='auto'>
					{patient !== null && patient.id ? (
						<>
							<PatientCardCollection
								savedPaymentMethods={savedPaymentMethods}
								updatePatient={updateUserHandler}
								patient={patient}
								deletePaymentMethod={deletePaymentMethodHandler}
								updatePatietnDefaultPaymentMethod={
									updateDefaultPaymentMethodHandler
								}
								createUserStripeIntentHandler={createUserStripeIntentHandler}
							/>
						</>
					) : (
						<PageLoaderView errorMessage='Not signed in' />
					)}
				</CareplanDetailFormView>
			</BasicChildContainer>
			{patient !== null && patient.id && (
				<>
					{/* <Button
						onClick={() => updateExportInvoiceOverlay(true)}
						backgroundColor={Colors.greenLightTeal}
						margin='16px'
						width='180px'
					>
						Export Invoice Data
					</Button> */}
					<Button
						onClick={() => toggleChangePasswordOverlay(true)}
						backgroundColor={Colors.blue}
						margin='16px'
						width='180px'
					>
						Change Password
					</Button>
					<Button
						onClick={() => logoutUser()}
						backgroundColor={Colors.red}
						margin='16px'
						width='180px'
					>
						Log Out
					</Button>
				</>
			)}
		</RelativeColumn>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		showInfoModel: (info) => dispatch(showInfoModel(info)),
		toggleChangePasswordOverlay: (status) => dispatch(toggleChangePasswordOverlay(status)),
	};
};

export default connect(null, mapDispatchToProps)(PatientProfileHandler);

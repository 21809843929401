import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import PageHeading from '../../../core/components/PageHeading';
import Text from '../../../../../corev2/Text';

import JobPost from '../../components/JobPost';
import CreateJobPostButton from '../../components/CreateJobPostButton';

import { jobPostsRoutes } from '../../routes/constants';

import { useAuth } from '../../../core/hooks/useAuth';
import { useFetchCarePlanJobPostsQuery } from '../../api/queries/useFetchCarePlanJobPostsQuery';

import { StyledJobPostsPage, StyledHeadingSection, StyledJobPostsContainer } from './styles';

const JobPostsPage = () => {
	const [jobPosts, setJobPosts] = useState([]);
	const history = useHistory();

	const { authUser } = useAuth();

	const { data } = useFetchCarePlanJobPostsQuery(authUser.carePlan.id);

	useEffect(() => {
		if (data) {
			setJobPosts(data);
		}
	}, [data]);

	const goToCreateJobPostPage = () => {
		history.push(jobPostsRoutes.create);
	};

	const goToJobPostPage = (jobPostId) => {
		history.push(jobPostsRoutes.jobPost(jobPostId));
	};

	return (
		<StyledJobPostsPage>
			<StyledHeadingSection>
				<PageHeading>Welcome Customer</PageHeading>

				<Text size='extraLarge'>
					Li Europan lingues es membres del sam familie. Lor separat existentie es un
					myth. Por scientie, musica, sport etc, litot.
					<br />
					<br />
					Li Europan lingues es membres del sam familie. Lor separat existentie es un
					myth. Por scientie, musica, sport etc, litot
				</Text>
			</StyledHeadingSection>

			<StyledJobPostsContainer>
				<CreateJobPostButton
					goToCreateJobPostPage={goToCreateJobPostPage}
				></CreateJobPostButton>

				{jobPosts.map((jobPost) => {
					return (
						<JobPost
							key={jobPost.id}
							title={jobPost.title}
							description={jobPost.description}
							goToJobPostPage={() => goToJobPostPage(jobPost.id)}
						/>
					);
				})}
			</StyledJobPostsContainer>
		</StyledJobPostsPage>
	);
};

export default JobPostsPage;

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import HomePage from '../pages/HomePage';
import AuthRoutes from '../../auth/routes';
import CarePlanRoutes from '../../careplan/routes';
import JobPostsRoutes from '../../jobPosts/routes';
import ProfileRoutes from '../../profile/routes';
import ChatRoutes from '../../chat/routes';

import PrivateRoute from './PrivateRoute';

import { coreRoutes } from './constants';

const AppRoutes = () => {
	return (
		<Switch>
			<Route path={coreRoutes.auth}>
				<AuthRoutes />
			</Route>

			<PrivateRoute path={coreRoutes.carePlan} component={CarePlanRoutes} />

			<PrivateRoute path={coreRoutes.jobPosts} component={JobPostsRoutes} />

			<PrivateRoute path={coreRoutes.profile} component={ProfileRoutes} />

			<PrivateRoute path={coreRoutes.chat} component={ChatRoutes} />

			<Route path={coreRoutes.root} exact>
				<HomePage />
			</Route>
		</Switch>
	);
};

export default AppRoutes;

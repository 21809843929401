import React from 'react';
import { useFormik } from 'formik-latest';

import FormWrapper from '../FormWrapper';
import InputContainer from '../InputContainer';

import { FormContainer, Select, ErrorMessage } from '../../../../../corev2/Forms';
import { PrimaryButton } from '../../../../../corev2/Buttons';

const RelationshipForm = (props) => {
	const { serverError, initialValues, relationshipHandler } = props;

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: (values) => relationshipHandler(values),
	});

	return (
		<FormContainer>
			<FormWrapper onSubmit={formik.handleSubmit}>
				<InputContainer>
					<Select
						name='relationship'
						onChange={formik.handleChange}
						placeholder='Email'
						values={formik.values.relationship}
						defaultValue='father'
					>
						<option value='father'>Father</option>
						<option value='mother'>Mother</option>
						<option value='son'>Son</option>
						<option value='daughter'>Daughter</option>
						<option value='friend'>Friend</option>
						<option value='self'>I am the client</option>
					</Select>
				</InputContainer>

				<InputContainer>
					<PrimaryButton size='small' type='submit'>
						Next
					</PrimaryButton>

					{serverError && <ErrorMessage> {serverError} </ErrorMessage>}
				</InputContainer>
			</FormWrapper>
		</FormContainer>
	);
};

export default RelationshipForm;

import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { PAYMENT_METHOD_MODEL } from '../../../core/api/models';

const deletePaymentMethod = async ({ sourceId, userId }) => {
	console.log(sourceId, userId);
	try {
		const data = await api.graph({
			query: `mutation {
				        deletePaymentMethod(
					        source_id: "${sourceId}",
					        user_id: ${userId}
					        user_type: "patient"
				        ) {
					${PAYMENT_METHOD_MODEL}
				}
			}`,
		});

		return data.deletePaymentMethod;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useDeletePaymentMethodMutation = () => {
	return useMutation({
		mutationKey: ['deletePaymentMethod'],
		mutationFn: (values) => {
			return deletePaymentMethod(values);
		},
	});
};

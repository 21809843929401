import React from 'react';

import Text from '../../../Text';

import {
	StyledSelectWrapper,
	StyledFilterLabelWrapper,
	StyledLabel,
	StyledDropDownContainer,
	StyledDropDown,
	StyledCustomInput,
} from './styles';

const Select = (props) => {
	const { name, label, options, customValue, filterHandler } = props;

	return (
		<StyledSelectWrapper>
			<StyledFilterLabelWrapper>
				<StyledLabel padding='5px'>{label}:</StyledLabel>
			</StyledFilterLabelWrapper>

			{Object.keys(options).map((key) => {
				const dropDownItems = options[key];
				const dropDownIsSelected = dropDownItems.some(
					(item, index) => index !== 0 && item.selected
				);

				const showCustomInput =
					dropDownItems[dropDownItems.length - 1].selected === true &&
					dropDownItems[dropDownItems.length - 1].name === 'Custom';

				return (
					<StyledDropDownContainer key={name}>
						<StyledDropDown
							onChange={(event) => filterHandler(name, key, event.target.value)}
							selected={dropDownIsSelected}
						>
							{dropDownItems.map((item) => {
								return (
									<option selected={item.selected} key={item.name}>
										<Text>{item.name}</Text>
									</option>
								);
							})}
						</StyledDropDown>

						{showCustomInput && (
							<StyledCustomInput
								selected={dropDownIsSelected}
								type='text'
								placeholder='Enter custom value'
								value={customValue}
								onChange={(event) =>
									filterHandler(name, key, null, event.target.value)
								}
							/>
						)}
					</StyledDropDownContainer>
				);
			})}
		</StyledSelectWrapper>
	);
};

export default Select;

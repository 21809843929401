import styled from 'styled-components';

export const StyledOnboardingPage = styled.div`
	display: flex;
	flex-direction: column;
`;

export const StyledStepsContainer = styled.div`
	margin-bottom: 3rem;
`;

import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';

import { ParentContainer } from 'AppComp/admin/ProviderSearch/ProviderSearchHandler';
import { RowFlex, ColumnFlex } from 'AppComp/styled/Basic';
import { device } from 'AppComp/styled/StyledMediaQuery';
import Button from 'AppComp/Button';
import Text from 'AppComp/Text';
import Title from 'AppComp/Title';
import Icon from 'AppComp/Icon';
import Colors from 'colors';
import styled from 'styled-components';
import { PHWhiteCon } from 'AppComp/provider/ProviderHomeDashboard';
import PaymentsTable from '../PaymentsTable';
import PayoutsTable from '../PayoutsTable';

import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { fetchProviderPayments, fetchProviderPayouts } from 'reducers/provider';

export const PHDColumn = styled(ColumnFlex)`
	padding: 30px 4px;
	border-radius: 12px;

	@media ${device.mobileS} {
		padding: 30px 16px;
	}

	@media ${device.laptop} {
		padding: 30px 32px 32px 52px;
	}
`;

const CreatedDateFilter = ({ sortOrder, handleDateFilterClick }) => {
	return (
		<ColumnFlex padding={'0px 0px 0px 20px'}>
			<Button
				style={{
					borderRadius: '16px',
					backgroundColor: sortOrder === 'ascending' ? Colors.emerald : 'white',
				}}
				margin='15px 0 15px 0'
				width={'215px'}
				height={'60px'}
				onClick={handleDateFilterClick}
			>
				<Text
					style={{
						textAlign: 'center',
					}}
					bold
					color={sortOrder === 'ascending' ? 'white' : Colors.emerald}
					midLarge
				>
					{sortOrder === 'ascending' ? 'Ascending Date' : 'Descending Date'}
				</Text>
				<Icon
					icon={sortOrder === 'ascending' ? faChevronUp : faChevronDown}
					color={sortOrder === 'ascending' ? 'white' : Colors.emerald}
					size='lg'
					style={{ marginLeft: '10px' }}
				/>
			</Button>
		</ColumnFlex>
	);
};

const SwitchTabsButton = ({ paymentType, handlePaymentsTypeSelect }) => {
	return (
		<ColumnFlex padding='10px 0' alignCenter gap='16px'>
			<ColumnFlex>
				<Button
					style={{
						borderRadius: '16px 16px 0 0',
					}}
					margin='0 0 0 0 '
					backgroundColor={paymentType === 'payments' ? Colors.emerald : 'white'}
					width={'200px'}
					height={'60px'}
					onClick={() => handlePaymentsTypeSelect('payments')}
				>
					<Text
						style={{
							textAlign: 'center',
						}}
						bold
						color={paymentType === 'payments' ? 'white' : Colors.emerald}
						midLarge
					>
						Payments
					</Text>
				</Button>

				<Button
					style={{
						borderRadius: '0 0 16px 16px',
					}}
					margin='0 0 0 0 '
					backgroundColor={paymentType === 'payouts' ? Colors.emerald : 'white'}
					width={'200px'}
					height={'60px'}
					onClick={() => handlePaymentsTypeSelect('payouts')}
				>
					<Text
						style={{
							textAlign: 'center',
						}}
						bold
						color={paymentType === 'payouts' ? 'white' : Colors.emerald}
						midLarge
					>
						Payouts
					</Text>
				</Button>
			</ColumnFlex>
		</ColumnFlex>
	);
};

const Payments = (props) => {
	const { payments, payouts, fetchProviderPayments, fetchProviderPayouts } = props;

	const [sortedPayments, setSortedPayments] = useState([]);
	const [sortedPayouts, setSortedPayouts] = useState([]);

	const [showTransactionsType, setShowTransactionsType] = useState('payments');

	const handlePaymentsTypeSelect = (paymentType) => {
		setShowTransactionsType(paymentType);
	};

	useEffect(() => {
		if (showTransactionsType === 'payments') {
			setSortedPayments(payments);
		}
		if (showTransactionsType === 'payouts') {
			setSortedPayouts(payouts);
		}
	}, [payments, payouts]);

	useEffect(() => {
		if (showTransactionsType === 'payments') {
			fetchProviderPayments();
		}

		if (showTransactionsType === 'payouts') {
			fetchProviderPayouts();
		}
	}, [showTransactionsType]);

	const [sortOrder, setSortOrder] = useState('ascending', 'descending');

	const sortTransactions = (transactions, sortBy, sortOrder) => {
		return transactions.sort((a, b) => {
			const dateA = new Date(a[sortBy]);
			const dateB = new Date(b[sortBy]);
			return sortOrder === 'ascending' ? dateA - dateB : dateB - dateA;
		});
	};

	const handleDateFilterClick = () => {
		const newSortOrder = sortOrder === 'ascending' ? 'descending' : 'ascending';
		setSortOrder(newSortOrder);

		if (showTransactionsType === 'payments') {
			const transactions = showTransactionsType === 'payments' ? payments : payouts;
			const sortedTransactions = sortTransactions(transactions, 'timestamp', newSortOrder);
			setSortedPayments(sortedTransactions);
		}
		if (showTransactionsType === 'payouts') {
			const transactions = showTransactionsType === 'payments' ? payments : payouts;
			const sortedTransactions = sortTransactions(transactions, 'created_at', newSortOrder);
			setSortedPayouts(sortedTransactions);
		}
	};

	return (
		<ParentContainer
			style={{
				minHeight: '100vh',
			}}
		>
			<PHDColumn fullHeight>
				<RowFlex gap='20px' fullHeight fullWidth columnMobile>
					<PHWhiteCon
						style={{
							flex: 1,
							backgroundColor: 'white',
							height: 'fit-content',
						}}
					>
						<Title
							subtitle
							color={Colors.greenLightTeal}
							margin='0 0 20px 0'
							fontWeight='700'
						>
							Filter
						</Title>
						<ColumnFlex
							style={{
								borderRadius: '16px',
								backgroundColor: Colors.lightGrey,
								padding: '10px 10px 10px 10px',
							}}
							fullWidth
							margin='10px 0 12px 0'
							alignCenter
						>
							<SwitchTabsButton
								paymentType={showTransactionsType}
								handlePaymentsTypeSelect={handlePaymentsTypeSelect}
							/>
						</ColumnFlex>
						<ColumnFlex
							style={{
								borderRadius: '16px',
								backgroundColor: Colors.lightGrey,
								padding: '10px 10px 10px 10px',
							}}
							fullWidth
							margin='10px 0 12px 0'
							alignStart
							gap='12px'
						>
							<ColumnFlex fullWidth alignStart>
								<Text
									alignStart
									fontStyle='bold'
									color='#969696'
									fontWeight='100'
									mediumText
								>
									By Date
								</Text>
							</ColumnFlex>
							<CreatedDateFilter
								sortOrder={sortOrder}
								handleDateFilterClick={handleDateFilterClick}
							/>
						</ColumnFlex>
					</PHWhiteCon>
					<div
						style={{
							flex: 5,
						}}
					>
						{showTransactionsType === 'payments' && (
							<PaymentsTable payments={sortedPayments} />
						)}
						{showTransactionsType === 'payouts' && (
							<PayoutsTable payouts={sortedPayouts} />
						)}
					</div>
				</RowFlex>
			</PHDColumn>
		</ParentContainer>
	);
};

const mapStateToProps = ({ provider }) => {
	return {
		payments: provider.payments,
		payouts: provider.payouts,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchProviderPayments: () => dispatch(fetchProviderPayments()),
		fetchProviderPayouts: () => dispatch(fetchProviderPayouts()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Payments);

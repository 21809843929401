import styled from 'styled-components';

import { colors } from '../../../utils';

export const StyledTextInputWrapper = styled.div`
	display: flex;
`;

export const StyledCustomInput = styled.textarea`
	border: 1px solid ${(props) => (props.selected ? colors.primary.primary : colors.gray)};
	border-radius: 5px;
	display: flex;
	margin: 0 0 0 10px;
	padding: 5px 10px;
	min-width: 80px;
	width: 100%;
	height: 50px;

	font-size: 14px;
	color: ${({ selected }) => (selected ? colors.primary.primary : colors.gray)};

	&::placeholder {
		color: ${({ selected }) => (selected ? colors.primary.primary : colors.gray)};
	}
`;

export const StyledFilterLabelWrapper = styled.div`
	display: flex;
	min-width: 170px;

	text-align: left;
`;

export const StyledLabel = styled.span`
	padding: 5px;
	color: ${colors.gray};
	font-size: 0.8rem;
`;

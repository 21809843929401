import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

const updateSetTraits = async ({ setId, traits }) => {
	try {
		const data = await api.graph({
			query: `mutation {
						updateSetTraits (
							set_id: ${setId},
							traits: ${api.graphStringify(traits)},
						) {
							id
							value {
								trait_value
							}
						}
					}`,
		});

		return data.updateSetTraits;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useUpdateSetTraitsMutation = () => {
	return useMutation({
		mutationKey: ['updateSetTraits'],
		mutationFn: (values) => {
			return updateSetTraits(values);
		},
	});
};

import React, { useState, useEffect, useCallback } from 'react';

import PageHeading from '../../../../core/components/PageHeading';
import DynamicForm, {
	serializer,
	filterParser,
	reverseParser,
} from '../../../../../../corev2/DynamicForm';

import { useAuth } from '../../../../core/hooks/useAuth';
import { useUpdateSetTraitsMutation } from '../../../api/mutations/useUpdateSetTraitsMutation';
import { useCheckUserSessionForCarePlanQuery } from '../../../api/queries/useCheckUserSessionForCarePlanQuery';

import needs from './needs.json';

import { StyledCareNeedsPage } from './styles';

const CareNeedsPage = () => {
	const [clientNeeds, setClientNeeds] = useState(needs);
	const [traits, setTraits] = useState([]);

	const { authUser } = useAuth();
	const updateSetTraitsMutation = useUpdateSetTraitsMutation();

	const { data } = useCheckUserSessionForCarePlanQuery();

	const careNeedsHandler = useCallback(
		(setId, traits) => {
			const debounce = setTimeout(async () => {
				try {
					await updateSetTraitsMutation.mutateAsync({ setId, traits });
				} catch (error) {
					console.error('Care plan care needs page failed', error);
					setServerError(error[0].message);
				}
			}, 1000);

			return () => clearTimeout(debounce);
		},
		[updateSetTraitsMutation]
	);

	useEffect(() => {
		if (data) {
			const traits = data.carePlan.careNeedsSet.setData;

			if (data) {
				setClientNeeds(reverseParser(traits, clientNeeds));
			}
		}
	}, [data]);

	useEffect(() => {
		if (authUser && traits.length) {
			const handler = careNeedsHandler(authUser.carePlan.careNeedsSet.id, traits);
			return () => handler();
		}
	}, [authUser, traits]);

	const searchFilterHandler = (...args) => {
		const { updatedFilters, parsedData } = filterParser(serializer, clientNeeds, ...args);

		setTraits(parsedData);
		setClientNeeds(updatedFilters);
	};

	return (
		<StyledCareNeedsPage>
			<PageHeading>Care Needs</PageHeading>

			<DynamicForm filters={clientNeeds} filterHandler={searchFilterHandler} />
		</StyledCareNeedsPage>
	);
};

export default CareNeedsPage;

import React, { useEffect, useState } from 'react';

import PageHeading from '../../../../core/components/PageHeading';
import PersonalInfoForm from '../../../components/PersonalInfoForm';

import { useUpdateSetTraitsMutation } from '../../../api/mutations/useUpdateSetTraitsMutation';
import { useCheckUserSessionForCarePlanQuery } from '../../../api/queries/useCheckUserSessionForCarePlanQuery';

import { useAuth } from '../../../../core/hooks/useAuth';

import { StyledPersonalPage } from './styles';

const PersonalPage = () => {
	const [jobStatement, setJobStatement] = useState('');
	const [weight, setWeight] = useState('');
	const [diagnosis, setDiagnosis] = useState('');
	const [emergencyContact1Name, setEmergencyContact1Name] = useState('');
	const [emergencyContact1Relationship, setEmergencyContact1Relationship] = useState('');
	const [emergencyContact1Phone, setEmergencyContact1Phone] = useState('');
	const [emergencyContact2Name, setEmergencyContact2Name] = useState('');
	const [emergencyContact2Relationship, setEmergencyContact2Relationship] = useState('');
	const [emergencyContact2Phone, setEmergencyContact2Phone] = useState('');

	const [serverError, setServerError] = useState('');

	const updateSetTraitsMutation = useUpdateSetTraitsMutation();
	const { authUser } = useAuth();

	const { data } = useCheckUserSessionForCarePlanQuery();

	useEffect(() => {
		if (data) {
			const traits = data.carePlan.carePlanSet.setData;
			setJobStatement(traits[7]?.value?.trait_value.replace(/\"/g, '') || '');
			setWeight(traits[8]?.value?.trait_value.replace(/\"/g, '') || '');
			setDiagnosis(traits[9]?.value?.trait_value.replace(/\"/g, '') || '');
			setEmergencyContact1Name(traits[10]?.value?.trait_value.replace(/\"/g, '') || '');
			setEmergencyContact1Relationship(
				traits[11]?.value?.trait_value.replace(/\"/g, '') || ''
			);
			setEmergencyContact1Phone(traits[12]?.value?.trait_value.replace(/\"/g, '') || '');
			setEmergencyContact2Name(traits[13]?.value?.trait_value.replace(/\"/g, '') || '');
			setEmergencyContact2Relationship(
				traits[14]?.value?.trait_value.replace(/\"/g, '') || ''
			);
			setEmergencyContact2Phone(traits[15]?.value?.trait_value.replace(/\"/g, '') || '');
		}
	}, [data]);

	const personalInfoFormHandler = async (values) => {
		try {
			await updateSetTraitsMutation.mutateAsync({
				setId: authUser.carePlan.carePlanSet.id,
				traits: [
					{ trait_id: 67, value: values.jobStatement },
					{ trait_id: 68, value: values.weight.toString() },
					{ trait_id: 69, value: values.diagnosis },
					{ trait_id: 70, value: values.emergencyContact1Name },
					{ trait_id: 71, value: values.emergencyContact1Relationship },
					{ trait_id: 72, value: values.emergencyContact1Phone },
					{ trait_id: 73, value: values.emergencyContact2Name },
					{ trait_id: 74, value: values.emergencyContact2Relationship },
					{ trait_id: 75, value: values.emergencyContact2Phone },
				],
			});
		} catch (error) {
			console.error('Care plan personal info form failed', error);
			setServerError(error[0].message);
		}
	};

	return (
		<StyledPersonalPage>
			<PageHeading>Personal Information</PageHeading>

			<PersonalInfoForm
				initialValues={{
					jobStatement: jobStatement,
					weight: weight,
					diagnosis: diagnosis,
					emergencyContact1Name: emergencyContact1Name,
					emergencyContact1Relationship: emergencyContact1Relationship,
					emergencyContact1Phone: emergencyContact1Phone,
					emergencyContact2Name: emergencyContact2Name,
					emergencyContact2Relationship: emergencyContact2Relationship,
					emergencyContact2Phone: emergencyContact2Phone,
				}}
				personalInfoFormHandler={personalInfoFormHandler}
				serverError={serverError}
			/>
		</StyledPersonalPage>
	);
};

export default PersonalPage;

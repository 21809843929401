import React, { useEffect, useState } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { MagicStuff } from './PatientCardCollectionViews';
import { ColumnFlex, RowFlex } from 'basicStyles';

import { ACCEPTED_CARDS } from 'constants';
import Colors from 'colors';
import AnimateHeight from 'react-animate-height';

import { SavedTokenInput, CardIconCon } from '../PatientSetting/PatientSettingViews';
import Text from 'AppComp/Text';
import Button from 'AppComp/Button';
import Icon from 'AppComp/Icon';

import { stripePromise } from '../../../utility/stripePromise';

const PatientCardCollectionHandler = (props) => {
	console.log('PatientCardCollectionHandler', props);
	const {
		updatePatietnDefaultPaymentMethod,
		deletePaymentMethod,
		savedPaymentMethods,
		patient,
		updatePatient,
		createUserStripeIntentHandler,
	} = props;

	const [userStripe, updateUserStripe] = useState(null);
	const [loading, updateLoader] = useState(false);
	const [err, updateErr] = useState(null);
	const [collectCardInfo, updateCollectCardInfoStatus] = useState(false);

	useEffect(() => {
		if (savedPaymentMethods.length === 0) {
			fetchStripeThing();
		}
	}, [savedPaymentMethods]);

	useEffect(() => {
		// updateUserStripe(null);
		updateCollectCardInfoStatus(false);
	}, [savedPaymentMethods]);

	const fetchStripeThing = async (props) => {
		const stripeClientThing = await createUserStripeIntentHandler();
		console.log('createUserStripeIntent', stripeClientThing);
		updateUserStripe(stripeClientThing);
		updateCollectCardInfoStatus(true);
	};
	const fetchStripeSC = async () => {
		try {
			const stripeClientThing = await createUserStripeIntentHandler();
			console.log('createUserStripeIntent', stripeClientThing);
			updateUserStripe(stripeClientThing);
			updateCollectCardInfoStatus(true);
		} catch (err) {
			console.log('something went wrong');
		}
	};

	const renderAcceptedCards = () => {
		return ACCEPTED_CARDS.map((d, i) => {
			return (
				<CardIconCon key={'card-key-' + d.id}>
					<Icon size='3x' color={Colors.primary.primary} icon={d.icon} />
				</CardIconCon>
			);
		});
	};
	const renderSavedTokensInputs = () => {
		return savedPaymentMethods.map((d, i) => {
			return (
				<SavedTokenInput
					key={'sti-' + d.id}
					primary={d.default_card === 1}
					{...d}
					user={patient}
					deletePaymentMethod={deletePaymentMethod}
					updateUserDefaultPaymentMethod={updatePatietnDefaultPaymentMethod}
				/>
			);
		});
	};
	return (
		<ColumnFlex alignCenter padding='32px'>
			<RowFlex alignCenter>
				<Text color={Colors.teal} largeText margin='0 0 12px 0 '>
					Payment Method
				</Text>

				{err && <Text error>{err}</Text>}
			</RowFlex>
			<Text darkGray margin='0 0 12px 0 ' mediumText>
				In Order to Provide our best service we must have a card on file...
			</Text>
			{savedPaymentMethods.length > 0 && (
				<>
					<Text color='green' mediumText margin='0px 0 6px 0'>
						Default Card
					</Text>
					<Text darkGray subtitle margin='0px 0 24px 0'>
						Select a card to make it your default card
					</Text>
					{renderSavedTokensInputs()}
					{!collectCardInfo && (
						<Button
							backgroundColor={Colors.primary.primary}
							margin='24px 0 0 0'
							onClick={() => fetchStripeSC()}
						>
							Add New Card
						</Button>
					)}
				</>
			)}
			<AnimateHeight duration={250} height={collectCardInfo ? 'auto' : '0%'}>
				<ColumnFlex center padding='16px 0'>
					<Text
						style={{ textAlign: 'center' }}
						darkGray
						largeText
						bold
						margin='32px 0 24px 0'
					>
						Please Input Card Information
					</Text>
					<RowFlex columnMobile gap='8px'>
						{renderAcceptedCards()}
					</RowFlex>
					{collectCardInfo && (
						<Elements stripe={stripePromise}>
							<MagicStuff
								updatePatient={updatePatient}
								userStripe={userStripe}
								loading={loading}
								err={err}
								updateErr={updateErr}
								updateLoader={updateLoader}
								userId={patient.id}
							/>
						</Elements>
					)}

					<Text
						onClick={() => updateCollectCardInfoStatus(false)}
						pointer
						underline
						darkGray
						subtitle
						margin='0px 0 24px 0'
					>
						Cancel
					</Text>
				</ColumnFlex>
			</AnimateHeight>
		</ColumnFlex>
	);
};

export default PatientCardCollectionHandler;
